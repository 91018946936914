
import {
  makeStyles, createStyles,
} from '@material-ui/core';


const useTwoColumnsStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '5vh',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  columns: {
    display: 'flex',
    justifyContent:'space-between',
    flexDirection: 'column',
    maxWidth: '1200px',
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: 'white',
    textAlign: 'center',
    width: '50%',
    margin: '50px auto 50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '50px auto 50px',
    },
  },
  description: {
    fontSize: '18px',
    lineHeight: '28px',
    color: 'white',
    margin: '0 0 10px',
    padding: '0 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  bottomWrapper: {
    margin:'20px 0 0 0',
    textAlign:'center'
  },
  bottom: {
    fontSize: '14px',
    lineHeight: '18px',
    color: 'white',
    textAlign: 'center',
    margin: '20px auto 0',
    padding: '20px 40px',
    borderTop: '1px solid white',
    display:'inline-block'
  },
  button: {
    padding:'12px 100px',
    '&:hover': {
      backgroundColor:'white',
      color: 'black',
    },
  }
}));

export default useTwoColumnsStyles;
