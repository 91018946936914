import React, { useEffect } from "react";
import { TimelineMax, TweenMax, Linear}  from 'gsap';

// const pathPrepare = ($el) => {
//   var lineLength = $el[0].getTotalLength();
//   $el.css("stroke-dasharray", lineLength);
//   $el.css("stroke-dashoffset", lineLength);
// }

const SvgLigneFrise = (props) => {

  const ScrollMagic = window.ScrollMagic;
  const $ = window.$;

  useEffect(() => {
    const $frise = $("path#frise");
    const $svg = $("#triggerSVGAnimation")

    // prepare SVG
    // pathPrepare($frise);
    const lineLength = $frise[0].getTotalLength();
    $frise.css("stroke-dasharray", lineLength);
    $frise.css("stroke-dashoffset", lineLength);

    // init controller
    const controller = new ScrollMagic.Controller();

    // build tween
    var tween = new TimelineMax()
      .add(TweenMax.to($frise, 0.9, { strokeDashoffset: lineLength*2, ease: Linear.easeNone })); // draw word for 0.9

    // build scene
    new ScrollMagic.Scene({ triggerElement: "#triggerSVGAnimation", duration: `${$svg.height()*0.9}`, tweenChanges: true })
      .setTween(tween)
      // .addIndicators() // add indicators (requires plugin)
      .addTo(controller);
  }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

  return (
    <svg viewBox="0 0 299 1827.09" width="1em" height="1em" {...props}>
      <g data-name="Calque 2">
        <path
          id="frise"
          d="M119.5 1821.59c0-351.91-111-274.27-111-519 0-217.18 285-210.81 285-486 0-235-288-190.52-288-416.4 0-159.34 118-210.55 118-394.69"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={11}
          data-name="Calque 1"
        />
      </g>
    </svg>
  );
}

export default SvgLigneFrise;