import {
    makeStyles, createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        position: 'absolute',
        bottom: 0,
        right: '4vw',
        height: 45,
        width: 100,
        transition: "all .3s ease-in-out",
        //opacity: .5,
        '&:hover': {
            height: 85,
            //opacity:1
        },
        "&.speaking": {
            height: 85,
            //opacity: 1
        },

        '@media (max-width:768px)': {
            display: "none",
        },
        zIndex:1,
    },
    owl: {
        height: 90,
        width: 100,
        position: 'absolute',
        top: 0,
        left:0,
        cursor:"pointer",
    },
    owlAnimation: {
        height: 150,
        width: 150,
        position: 'absolute',
        top: -25,
        left: -25,
    },
    quote: {
        position: 'absolute',
        bottom: 110,
        right:0,
        width: 400,
        color: "#fff",
        background: "rgb(118 181 128 / 60%)",
        padding: "32px 15px 10px 25px",
        borderRight: "5px solid #1b573a",
        '&::before': {
            content: '"“"',
            fontFamily: 'Georgia',
            fontSize: 50,
            lineHeight: 0,
            display: 'block',
            position: 'absolute',
            left:10,
            top:32,
        },
        '&::after': {
            content: '""',
            marginTop: -30,
            paddingTop: 0,
            position: 'absolute',
            bottom: -30,
            right: 15,
            borderWidth: '30px 30px 0 0',
            borderStyle: 'solid',
            borderColor: "rgb(118 181 128 / 60%) transparent",
            display: 'block',
            width: 0,
        }
    },
    writer:{
        position: 'relative'
    }
}));

export default useStyles;
