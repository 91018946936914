
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_1.jpg';
import BackgroundTablette from '../../../assets/images/bg_1_tablette.jpg';
import BackgroundMobile from '../../../assets/images/bg_1_mobile.jpg';


const useHeroBannerStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        background: `url(${Background}) no-repeat center 100%`,
        backgroundSize: "cover",
      },
      [theme.breakpoints.down("900")]: {
        backgroundImage: `url(${BackgroundTablette})`,
      },
      [theme.breakpoints.down("720")]: {
        backgroundImage: `url(${BackgroundMobile})`,
      },
    },
    background: {
      background: `url(${Background}) no-repeat 0 100% ${theme.palette.primary.main}`,
      backgroundSize: "cover",
      [theme.breakpoints.down("md")]: {
        display: 'none'
      },
    },
    content: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: "100vh",
      flexDirection: "column",
      position: "relative",
    },
    title1: {
      position: "absolute",
      top: "80px",
      right: "10%",
      color: "white",
      width: "40%",
      fontSize: "2vw",
      lineHeight: "2.8vw",
      zIndex: '100',
      "& span": {
        fontFamily: "Oswald-Bold",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "22px",
        lineHeight: "32px",
        width: "100%",
        position: "relative",
        top: "20px",
        right: "0",
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    title2: {
      color: "white",
      position: "absolute",
      bottom: "80px",
      left: "10%",
      width: "40%",
      fontSize: "3vw",
      lineHeight: "4vw",
      textAlign: "right",
      zIndex: '100',
      "& span": {
        textTransform: "uppercase",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "22px",
        lineHeight: "32px",
        width: "100%",
        position: "absolute",
        bottom: "50px",
        left: "0",
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    logo: {
      width: '28vh',
      display:'block',
      position: "absolute",
      bottom: "80px",
      right: "10%",
      zIndex: '100',
      [theme.breakpoints.down("sm")]: {
        bottom: "180px",
        right: "15%",
        width: '30%',
      },
    },
    city: {
      width: '90%',
      height: '100%',
      display: 'block',
      position: "absolute",
      bottom: "20%",
      right: "10%",
      zIndex: '10',
      [theme.breakpoints.down("sm")]: {
        bottom: "15%",
        width: '200%',
        height: '80%',
        right: "-55%",
      },
    },
  })
);

export default useHeroBannerStyles;
