import React, { useEffect } from "react";
import clsx from "clsx";
import historyPartThreeStyles from "./HistoryPartThree.style";

let canvas,
    stage,
    exportRoot,
    fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
    canvas = document.getElementById("HerissonAnimation");
    var comp = AdobeAn.getComposition("63FF586D341B864B949F9FBBB948F1CE");
    handleComplete({}, comp);
};

const handleComplete = (evt, comp) => {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib = comp.getLibrary();
    exportRoot = new lib.herisson();
    stage = new lib.Stage(canvas);
    //Registers the "tick" event listener.
    fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", stage);
    };

    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
};

const HerissonAnimation = (props) => {
    const classes = historyPartThreeStyles(props);
    const ScrollMagic = window.ScrollMagic;
    const $ = window.$;

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        // build scene
        new ScrollMagic.Scene({
            triggerElement: "#triggerHerissonAnimation",
            duration: "120%",
        })
            .addTo(controller)
            // .addIndicators() // add indicators (requires plugin)
            .on("enter", function (e) {
                init();

                // setTimeout(function () {
                //   $("#HerissonAnimation").show();
                // }, 100);
            })
        // .on("leave", function (e) {
        //   $("#HerissonAnimation").hide();
        // });
    }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

    return (
        <canvas
            id="HerissonAnimation"
            width="150" height="150"
            className={clsx(classes.herisson)}
        ></canvas>
    );
};

export default HerissonAnimation;
