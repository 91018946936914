import React from 'react';
import Layout from '../components/Layout';
import HeroBanner from '../components/HeroBanner';
import TwoColumnsLeftImage from '../components/TwoColumnsLeftImage';
import TwoColumnsRightImage from '../components/TwoColumnsRightImage';
import TwoColumns from '../components/TwoColumns';
import ThreeColumns from '../components/ThreeColumns';
import SeeMore from '../components/SeeMore';
import EmptyBackground from '../components/EmptyBackground';

const Template = () => {
  const fullpage_api = window.fullpage_api;

  if(fullpage_api) fullpage_api.destroy('all')

  return (
    <Layout page="home">
      <HeroBanner />
      <TwoColumnsLeftImage />
      <TwoColumns />
      <TwoColumnsRightImage />
      <ThreeColumns />
      <SeeMore />
      <EmptyBackground />
    </Layout>
  );
}
  
  
  


export default Template;
