
import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useHowToPlantStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.dark,
    height: '250vw',
    // [theme.breakpoints.up('xl')]: {
    //   height: '700vh',
    // },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4vw 0 8vw',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding:'20px'
    },
  },
  pioupiou: {
    width: '150px',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
    },
  },
  title: {
    width: '100%',
    color: 'white',
    textAlign: 'center',
    fontSize: '36px',
    lineHeight: '50px',
    margin: '0 0 28px',
    '& span': {
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '28px',
      width: '100%',
      '& br': {
        display: 'none'
      },
    },
  },
  subtitle: {
    width: '60%',
    color: 'white',
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '32px',
    margin: '0 0 10px',
    '& span': {
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '26px',
      width: '100%',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4vh',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '0 0 20px',
    position:'absolute',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      position:'static'
    },
  },
  twoColumns: {
    margin: '0 0 60px',
    maxWidth:'1200px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 20px',
      width: '100%',
      textAlign: 'center'
    },
    
  },
  alignLeft: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'right'
  },
  alignRight: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  box: {
    width: '32%',
    position: 'relative',
    display:'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      display:"block"
    },
  },
  picture: {
    flex: '0 0 60%',
    textAlign: 'center',
    position:'relative',
    '& img': {
      width: '75%',
      zIndex: '2',
      position:'relative'
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      '& img': {
        width: '80%'
      },
    },
  },
  txt: {
    flex: '0 0 40%',
    margin: '20px 0 20px',
  },
  titleContent: {
    color: 'white',
    fontSize: '48px',
    lineHeight: '58px',
    margin: '0 0 16px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '28px',
      margin: '0 0 8px',
      padding: '0 20px',
      textAlign: 'left'
    },
  },
  subtitleContent: {
    color: 'white',
    fontSize: '38px',
    lineHeight: '48px',
    margin: '0 0 10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18x',
      lineHeight: '42px',
      padding: '0 20px',
      textAlign:'left'
    },
  },
  description: {
    color: 'white',
    fontSize: '20px',
    lineHeight: '32px',
    margin: '0 0 10px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '26px',
      padding: '0 20px',
      textAlign: 'left',
      '& br': {
        display: 'none'
      },
    },
  },
  branches: {
    position: 'absolute',
    display: 'block',
    bottom: '16vw',
    width: '59vw',
    maxWidth:'680px',
    left: 0,
    zIndex: '1',
    [theme.breakpoints.up('xl')]: {
      bottom: '10vw',
      width: '56vw',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '18vw',
      width: '90vw',
      left: '-2vh',
    },
  },
  frise: {
    width: '24vw',
    height: '146vw',
    position: 'absolute',
    top: '45vw',
    right: '35vw',
    [theme.breakpoints.up('xl')]: {
      top: '29vw',
    },
    [theme.breakpoints.down('sm')]: {
      display:'none'
    },
  },
  friseMobileContainer: {
    borderLeft: '10px solid white',
    width:"100%"
  },
  action1: {
    top: '77vw',
    left: 0,
    [theme.breakpoints.up('xl')]: {
      top: '61vw',
    },
    [theme.breakpoints.down('sm')]: {
      top: 0
    },
  },
  ActionAgroAnim: {
    width: "22vw",
    position: 'absolute',
    top: '-5vw',
    right:'-26vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  ActionAgroAnimMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    width: "70vw",
    position: 'relative',
    left: '-4vw',
    margin:'32px 0 10px'
  },
  action2: {
    top: '110vw',
    left: 0,
    [theme.breakpoints.up('xl')]: {
      top: '94vw',
    },
    [theme.breakpoints.down('sm')]: {
      top: 0
    },
  },
  ActionApiAnim: {
    width: "22vw",
    position: 'absolute',
    top: '-4vw',
    left: '-20vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  ActionApiAnimMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    width: "70vw",
    position: 'relative',
    left: '-4vw',
    margin: '32px 0 10px'
  },
  action3: {
    top: '147vw',
    left: 0,
    [theme.breakpoints.up('xl')]: {
      top: '131vw',
    },
    [theme.breakpoints.down('sm')]: {
      top:0
    },
  },
  ActionSauvageAnim: {
    width: "22vw",
    position: 'absolute',
    top: '-4vw',
    right: '-24vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  ActionSauvageAnimMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    width: "70vw",
    position: 'relative',
    left: '-4vw',
    margin: '32px 0 10px'
  },
  ActionPlantationAnim: {
    width: "280px",
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    },
  },
}));

export default useHowToPlantStyles;
