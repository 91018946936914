import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useHeroBannerStyles from './HeroBanner.style';

const HeroBanner = (props) => {
  const classes = useHeroBannerStyles(props);
  const { h1, h2 } = props;

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content}>
        <Typography variant="h1" className={clsx(classes.h1)}>
          {h1 ? h1 : 'Titre H1 de la page' }
          </Typography>
        <Typography variant="h2" className={clsx(classes.description)}>
          {h2 ? h2 : 'Titre H2 de la page'}
        </Typography>
      </div>
    </div>
  );
};

export default HeroBanner;
