import React, { useState } from "react";

import clsx from "clsx";
import useWiseOwlStyles from "./WiseOwl.style";
import Typewriter from "typewriter-effect";
import WiseOwlAnimation from './WiseOwl.anim'
import { quotes } from "./quotes"

function WiseOwl(props) {
    const classes = useWiseOwlStyles(props);
    const [displayQuote, setDisplayQuote] = useState(false);
    const quote = quotes[Math.floor(Math.random() * quotes.length)];
    return (
        <div className={clsx(classes.root, displayQuote ? 'speaking' : '')}>
            <div
                className={clsx(classes.owl)}
                onClick={() => setDisplayQuote(true)}
            >
                <WiseOwlAnimation />
            </div>
            {displayQuote &&
                <div className={clsx(classes.quote)}>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter
                                .typeString(quote)
                                .callFunction((state) => {
                                    setTimeout(function () {
                                        setDisplayQuote(false);
                                    }, 1500);
                                })
                                .start()
                        }}
                        options={{
                            delay: 30,
                            loop: false,
                        }}
                    />
                </div>
            }
        </div>
    );
}

export default WiseOwl;
