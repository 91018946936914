import React, { useContext } from 'react';
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useHistoryMushroomStyles from "./HistoryMushroom.style";
import MushroomAnimation from './Mushroom.anim';
import aureoleImg from '../../../assets/images/aureole.png';
import handsImg from '../../../assets/images/mains.svg';
import signalImg from '../../../assets/images/panneau.png';
import grassImg from '../../../assets/images/grass.svg';
import { Tween, Reveal } from "react-gsap";
import { InfoContext } from '../index'

const HistoryMushroom = (props) => {
  const classes = useHistoryMushroomStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 992

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content} id='triggerMushroomAnimation'>
        <Reveal repeat={repeat}>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
          >
            <img src={aureoleImg} className={clsx(classes.aureole)} alt="" />
            <Typography variant="body2" className={clsx(classes.title)}>
              Heureusement, un miracle se produit !
            </Typography>
            <Typography variant="h3" className={clsx(classes.h3)}>
              C’est la multiplication des solutions <br />
              « innovantes &amp; écoresponsables »
              <img src={signalImg} className={clsx(classes.signal)} alt="" />
            </Typography>
            <img src={handsImg} className={clsx(classes.hands)} alt="" />
            <MushroomAnimation />
            <Typography variant="body2" className={clsx(classes.text)}>
              <img src={grassImg} className={clsx(classes.grass1)} alt="" />
              En réalité, pas facile d’y voir clair dans cette abondance <br />
              où <strong>écoresponsabilité rime surtout avec monnaie</strong>.
              <img src={grassImg} className={clsx(classes.grass2)} alt="" />
            </Typography>
          </Tween>
        </Reveal>
      </div>
      <div className={clsx(classes.background, "fp-bg")} id="bgMushroom"></div>
    </div>
  );
};

export default HistoryMushroom;
