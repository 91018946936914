
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../assets/images/img_arbre.jpg';

const useTwoColumnsStyles = makeStyles((theme) => createStyles({
  root: {
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    maxWidth: '1200px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  image: {
    background: `url(${Background}) no-repeat center center`,
    backgroundSize: 'cover',
    position: 'relative',
    flex: '1 1 50%',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
      paddingTop:'100%'
    },
  },
  content: {
    padding: '5vh 30vh 5vh 5vh',
    flex: '1 1 50%',
    [theme.breakpoints.down('md')]: {
      padding: '5vh 15vh 5vh 5vh',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
      padding: '20px',
    },
  },
  title: {
    margin: '0 0 20px'
  },
  description: {
    margin: '0 0 10px',
  },
}));

export default useTwoColumnsStyles;
