import React from 'react';
import clsx from 'clsx';
import Header from '../Header';
import Footer from '../Footer';
import AudioPlayer from '../AudioPlayer';
import useLayoutStyles from './Layout.style';

const Layout = (props) => {
  const {
    children,
  } = props;
  const classes = useLayoutStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <Header menu />
      <AudioPlayer />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
