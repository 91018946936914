import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_competences.jpg';
import BackgroundTablette from '../../../assets/images/bg_competences_tablette.jpg';
import BackgroundMobile from '../../../assets/images/bg_competences_mobile.jpg';
import box_pot from '../../../assets/images/box_pot.svg';
import box_ruche from '../../../assets/images/box_ruche.svg';

const useServicesStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "8vh",
      height: "auto",
      /*[theme.breakpoints.down("lg")]: {
        height: "120vw",
      },
      "@media all and (min-width: 1200px) and (min-height: 700px)": {
        height: "120vw",
      },*/
      [theme.breakpoints.down("901")]: {
        padding: "4vh",
        background: `url(${BackgroundTablette}) no-repeat fixed center`,
        backgroundSize: "cover",
      },
      [theme.breakpoints.down("720")]: {
        backgroundImage: `url(${BackgroundMobile})`,
      },
    },
    background: {
      background: `url(${Background}) no-repeat 0 100%`,
      backgroundSize: "cover",
      [theme.breakpoints.down("900")]: {
        display: "none",
      },
    },
    scrollWrapper: {
      maxWidth: "1200px",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        // height: '92vh',
        // overflow:'scroll'
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
      position: "relative",
      boxSizing: "border-box",
    },
    column: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      position:'relative'
    },
    grid: {
      width: "100%",
      margin: "0 0 150px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 42px",
      },
    },
    wrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: "0 0 40px",
    },
    rowContent: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "100%",
    },
    gridContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
    },
    item: {
      flex: "0 0 220px",
      width: "220px",
      maxWidth: "220px",
      margin: "20px auto",
      padding: "0 25px",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto 42px",
      },
    },
    titleCategory: {
      color: "white",
      textAlign: "center",
      fontSize: "38px",
      lineHeight: "50px",
      margin: "0 0 24px",
      textTransform: "uppercase",
      fontFamily: "Oswald-Bold",
      [theme.breakpoints.up("xl")]: {
        fontSize: "48px",
        lineHeight: "58px",
      },
    },
    iconCategory: {
      height: "160px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0 0 20px",
    },
    pot: {
      background: `url(${box_pot}) no-repeat center center`,
      backgroundSize: "contain",
    },
    ruche: {
      background: `url(${box_ruche}) no-repeat center 70%`,
      backgroundSize: "80% 80%",
      position: "relative",
      top: "17px",
      "& p": {
        marginTop: "0px",
      },
    },
    titleItem: {
      color: "white",
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "40px",
      fontFamily: "Oswald-Bold",
      marginTop: "30px",
      textTransform: "uppercase",
    },
    descriptionItem: {
      color: "white",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "22px",
      "& span": {
        fontFamily: "Oswald-Bold",
        textTransform: "uppercase",
      },
    },
    title: {
      width: "70%",
      color: "white",
      textAlign: "center",
      fontSize: "38px",
      lineHeight: "50px",
      margin: "0 0 40px",
      textTransform: "uppercase",
      "& span": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "40px",
        width: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "48px",
        lineHeight: "58px",
      },
    },
    row: {
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: "0 0 20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    hideOnMobile: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    col: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      flex: "1 1 50%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        flex: "1 1 100%",
        margin: "0 0 20px",
      },
    },
    txt: {
      width: "80%",
      color: "white",
      fontSize: "24px",
      lineHeight: "32px",
      margin: "0 0 10px",
      textAlign: "center",
      "& span": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: "18px",
        lineHeight: "26px",
        margin: "0 0 20px",
        "& br": {
          display: "none",
        },
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "36px",
        lineHeight: "48px",
      },
    },
    icon: {
      width: "50px",
      margin: "20px 0",
    },
    button: {
      padding: "32px !important",
      borderWidth: "4px",
      fontSize: "20px !important",
      fontFamily: "Oswald-Light",
      fontStyle: "italic",
      lineHeight: "26px !important",
      textAlign: "center",
      fontWeight:"400 !important",
      "&:hover": {
        backgroundColor: "white !important",
        color: "black !important",
        textDecoration: "none",
      },
      "& > span": {
        display: "block",
      },
      "& > span > span.bold": {
        fontFamily: "Oswald-Bold",
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "32px !important",
        lineHeight: "42px !important",
      },
    },
    bee: {
      position: 'absolute',
      bottom: '85%',
      right: '40%',
      zIndex: '1000',
      width: '320px',
      height: '500px',
      [theme.breakpoints.down("sm")]: {
        right: '0%',
      },
    }
  })
);

export default useServicesStyles;
