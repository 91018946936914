import React, { useContext} from 'react';
import clsx from 'clsx';
import {
  Typography, Link
} from '@material-ui/core';
import { Button } from '../../../components/Button';
import WiseOwl from "../../../components/WiseOwl";
import useContactStyles from './Contact.style';
import { Tween, Reveal } from "react-gsap";
import { InfoContext } from '../index'

const Contact = (props) => {
  const classes = useContactStyles(props);
  const size = useContext(InfoContext);

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.background, "fp-bg")}></div>
      <Reveal repeat={size.width > 900}>
        <Tween
          from={{ opacity: 0, y: "20", ease: "Strong.easeOut" }}
          duration={2}
        >
          <div className={clsx(classes.wrapper)}>
            <Typography variant="h3" className={clsx(classes.title)}>
              Bonjour !
            </Typography>
            <Typography variant="body2" className={clsx(classes.description)}>
              Connectons-<span>nous</span> et devenons des{" "}
              <span>gardiens de la nature</span>
            </Typography>
            <Button
              className={clsx(classes.button)}
              variant="outlined"
              href="mailto:bonjour@vertuelle.com"
              component={Link}
            >
              Ecrivez-nous
            </Button>
          </div>
        </Tween>
        <WiseOwl id="wiseOwlWrapper" />
      </Reveal>
    </div>
  );
};

export default Contact;
