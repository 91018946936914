import * as React from "react";

function SvgIconFacebook(props) {
  return (
    <svg
      data-name="Groupe 19"
      width="1em"
      height="1em"
      viewBox="0 0 23.46 46.94"
      {...props}
    >
      <path
        data-name="Forme 1305"
        d="M19.19 7.8h4.28V.34a53.328 53.328 0 00-6.24-.33C11.05.01 6.82 3.89 6.82 11.03v6.58H0v8.34h6.82v20.99h8.36V25.95h6.54l1.04-8.34h-7.58v-5.75c0-2.41.65-4.06 4.01-4.06z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconFacebook;