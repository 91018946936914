import React from 'react';
import clsx from 'clsx';
import useEmptyBackgroundStyles from './EmptyBackground.style';

const EmptyBackground = (props) => {
  const classes = useEmptyBackgroundStyles(props);

  return (
    <div className={clsx(classes.root)}>
    </div>
  );
};

export default EmptyBackground;
