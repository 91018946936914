import * as React from "react";

function SvgIconInstagram(props) {
  return (
    <svg
      data-name="Groupe 21"
      width="1em"
      height="1em"
      viewBox="0 0 47.07 46.97"
      {...props}
    >
      <path
        data-name="Forme 1306"
        d="M23.54 11.4A12.06 12.06 0 1035.6 23.46 12.063 12.063 0 0023.54 11.4zm0 19.89a7.83 7.83 0 117.83-7.83 7.823 7.823 0 01-7.83 7.83zM33.22.12c-4.32-.21-15.04-.2-19.37 0-3.8.18-7.16 1.09-9.86 3.8C-.53 8.44.04 14.53.04 23.46c0 9.14-.51 15.09 3.95 19.55 4.54 4.53 10.72 3.94 19.55 3.94 9.05 0 12.18 0 15.38-1.24 4.36-1.69 7.65-5.58 7.97-12.57.2-4.32.19-15.03 0-19.36C46.5 5.53 42.07.52 33.22.12zm6.85 39.89c-2.97 2.97-7.08 2.7-16.59 2.7-9.8 0-13.72.15-16.59-2.72-3.3-3.29-2.7-8.56-2.7-16.56 0-10.82-1.11-18.61 9.75-19.17 2.5-.09 3.23-.12 9.51-.12l.09.06c10.44 0 18.63-1.09 19.12 9.77.11 2.47.14 3.22.14 9.49 0 9.68.18 13.63-2.73 16.55zm-3.99-31.9a2.815 2.815 0 11-2.82 2.82 2.823 2.823 0 012.82-2.82z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconInstagram;