import React from 'react';
import Layout from '../../components/Layout';
import OneColumn from './OneColumn';

const MentionsLegales = () => {
  const fullpage_api = window.fullpage_api;

  if(fullpage_api) fullpage_api.destroy('all')

  return (
    <Layout page="home">
      <OneColumn />
    </Layout>
  );
}
  
  
  


export default MentionsLegales;
