import React, { useContext } from 'react';
import {
  Typography, Link, ButtonBase
} from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
import { Button } from '../Button';
import useFooterStyle from './Footer.style';
import logo from '../../assets/images/logo_footer.png';
import IconFacebook from '../../assets/images/jsx/IconFacebook';
import IconVertuelle from '../../assets/images/jsx/IconVertuelle';
import IconInstagram from '../../assets/images/jsx/IconInstagram';
import { InfoContext } from './../../pages/Home/index'

const Footer = (props) => {
  const classes = useFooterStyle(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900
  const NavLink = repeat ? Link : HashLink;

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.items)}>
        <div className={classes.item}>
          <ButtonBase
            to='/#home'
            href='/#home'
            component={NavLink}
            className={clsx(classes.link)}>
            <img src={logo} className={clsx(classes.logo)} alt="Logo Vertuelle" />
          </ButtonBase>
          <Typography
            variant="body2"
            className={clsx(classes.title)}
          >
            Maison de communication
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.subtitle)}
          >
            Écoresponsable, humaniste & vertueuse
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.description)}
          >
            Nous sommes Vertuelle, une maison de communication écologique, spécialisée dans le secteur du web, et résolument attachée au respect de l’humain et de l’environnement. Nous voulons être un acteur de la transition écologique, en adoptant une démarche responsable par la compensation carbone de notre production et de celle des nos clients, à travers notre association.
          </Typography>
        </div>
        <div className={classes.item}>
          <Typography
            variant="body2"
            className={clsx(classes.nav)}
          >
            <ButtonBase
              to='/#history'
              href='/#history'
              component={NavLink}
              className={clsx(classes.link)}>
              Notre histoire
            </ButtonBase>
            <ButtonBase
              to='/#vertuelle'
              href='/#vertuelle'
              component={NavLink}
              className={clsx(classes.link)}>
              Le Nom Vertuelle
            </ButtonBase>
            <ButtonBase
              to='/#how-to-plant'
              href='/#how-to-plant'
              component={NavLink}
              className={clsx(classes.link)}>
              Comment planter des arbres
            </ButtonBase>
            <ButtonBase
              to='/#services'
              href='/#services'
              component={NavLink}
              className={clsx(classes.link)}>
              Nos services
            </ButtonBase>
            <ButtonBase
              to='/#contact'
              href='/#contact'
              component={NavLink}
              className={clsx(classes.link)}>
              Nous contacter
            </ButtonBase>
          </Typography>
        </div>
        <div className={classes.item}>
          <div className={clsx(classes.social)}>
            <Link href="https://www.facebook.com/vertuelle" target="_blank" className={clsx(classes.link, classes.linkSocial)}>
              <IconFacebook className={clsx(classes.icon)}/>
            </Link>
            <Link href="https://www.instagram.com/vertuelle_agency/" className={clsx(classes.link, classes.linkSocial)}>
              <IconInstagram className={clsx(classes.icon)} />
            </Link>
            <Link href="https://www.vertuelle.com" className={clsx(classes.link, classes.linkSocial)}>
              <IconVertuelle className={clsx(classes.icon)} />
            </Link>
          </div>
          <Button
            className={clsx(classes.button)}
            variant="outlined"
            href="mailto:bonjour@association-vertuelle.org"
            component={Link}
          >
            <span>Devenir membre de</span>
            <span className='bold'>L’association vertuelle</span>
          </Button>
          <Typography
            variant="body2"
            className={clsx(classes.mentions)}
          >
            CS 20005 - 29 Bd de la Ferrage 06400 CANNES<br />
            bonjour@vertuelle.com
          </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.mentions)}
          >
            <Link href="https://www.eklecty-city.fr/interviews/interview-avec-stephane-picq-le-compositeur-de-lost-eden-et-dune/" target="_blank" className={clsx(classes.link, classes.creditsAudio)}>
              Crédit audio : <i>Wake Up</i> - Stéphane Picq (Dune: Spice Opera)
            </Link><br />
            <HashLink className={clsx(classes.link)} to="/mentions-legales">
              Mentions Légales
            </HashLink>
            <HashLink className={clsx(classes.link)} to="/politique-confidentialite">
              Politique de Confidentialité
            </HashLink>
            © {new Date().getFullYear()} Vertuelle
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
