import React from 'react';
import clsx from 'clsx';
import {
  Typography,
} from '@material-ui/core';
import useVertuelleStyles from './VertuelleName.style';
import { Tween, Reveal } from 'react-gsap';
import VertuelleAnimation from "./Vertuelle.anim";
import VertuelleAnimationMobile from "./VertuelleMobile.anim";

const VertuelleFruits = (props) => {
  const classes = useVertuelleStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.header)}>
        <Reveal repeat>
          <Tween from={{ opacity: 0, y: '20', ease: 'Strong.easeOut' }} duration={2}>
            <Typography
              variant="h2"
              className={clsx(classes.h2)}
              id='triggerVertuelleAnimation'
            >
              C'est pourquoi, nous avons créé :
            </Typography>
          </Tween>
        </Reveal>

      </div>
      <div className={clsx(classes.wrapper)}>
        <VertuelleAnimation />
        <VertuelleAnimationMobile />
      </div>
    </div>
  );
};

export default VertuelleFruits;
