
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_mushroom.jpg';
import BackgroundTablette from '../../../assets/images/bg_mushroom.jpg';
import BackgroundMobile from '../../../assets/images/bg_mushroom.jpg';


const useHistoryMushroomStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh',
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.down("md")]: {
      background: `url(${Background}) no-repeat center`,
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("900")]: {
      backgroundImage: `url(${BackgroundTablette})`,
    },
    [theme.breakpoints.down("720")]: {
      backgroundImage: `url(${BackgroundMobile})`,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '4vh',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: '10',
  },
  h3: {
    color: 'white',
    textAlign: 'center',
    fontSize: '25px',
    lineHeight: '1.3',
    textTransform: 'uppercase',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      fontSize: '27px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '34px',
      lineHeight: '1.2',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '41px',
    },
    "& br": {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    }
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontSize: '19px',
    lineHeight: '1.4',
    fontWeight: '300',
    paddingBottom: '.5em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '26px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '32px',
    }
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '1.3',
    marginTop: '-10px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-30px',
      fontSize: '21px',
    },
    [theme.breakpoints.up('502')]: {
      fontSize: '23px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
      marginTop: '-70px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
      marginTop: '-76px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '34px',
      marginTop: '-80px',
    },
    "& br": {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    }
  },
  signal: {
    position: 'absolute',
    height: '84px',
    width: '70px',
    right: '-10px',
    bottom: '-90px',
    [theme.breakpoints.up('sm')]: {
      height: '96px',
      width: '80px',
      right: '-20px',
      top: '80px',
      bottom: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: '144px',
      width: '120px',
      right: '-100px',
      top: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '216px',
      width: '180px',
      right: '-210px',
      top: '-50px',
    }
  },
  aureole: {
    width: '100px',
    [theme.breakpoints.up('md')]: {
      width: '120px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '130px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '140px',
    },
  },
  hands: {
    marginTop: '14px',
    width: '34px',
    [theme.breakpoints.up('md')]: {
      width: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '48px',
    },
  },
  grass1: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      height: '52px',
      width: '80px',
      left: '-90px',
      top: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '69px',
      width: '106px',
      left: '-170px',
      top: '20px',
    }
  },
  grass2: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      height: '53px',
      width: '82px',
      right: '-125px',
      top: '-15px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '72px',
      width: '112px',
      right: '-170px',
      top: '-40px',
    }
  },
  MushroomAnim: {
    width: '480px',
    height: '227px',
    marginTop: '-55px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '-100px',
      width: '768px',
      height: '363px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-120px',
      width: '992px',
      height: '469px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-140px',
      width: '1120px',
      height: '530px',
    },
  },
  background: {
    background: `url(${Background}) no-repeat center`,
    backgroundSize: 'cover',
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
  },
  /*
  icon: {
    width: '50px',
    margin: '20px 0',
    [theme.breakpoints.up('xl')]: {
      width: '70px',
    },
  }*/
}));

export default useHistoryMushroomStyles;
