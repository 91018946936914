
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_deny.jpg';
import BackgroundTablette from '../../../assets/images/bg_deny.jpg';
import BackgroundMobile from '../../../assets/images/bg_deny.jpg';


const useHistoryDenyStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh',
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.down("md")]: {
      background: `url(${Background}) no-repeat center bottom`,
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("900")]: {
      backgroundImage: `url(${BackgroundTablette})`,
    },
    [theme.breakpoints.down("720")]: {
      backgroundImage: `url(${BackgroundMobile})`,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '4vh',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: '10',
  },
  h3: {
    color: 'white',
    textAlign: 'center',
    fontSize: '34px',
    lineHeight: '1.4',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '50px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '68px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '78px',
    }
  },
  title: {
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '1.4',
    fontWeight: '300',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '35px',
    }
  },
  denyAnim: {
    width: '480px',
    height: '227px',
    margin: '50px 0 30px',
    [theme.breakpoints.up('sm')]: {
      width: '768px',
      height: '363px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '30px 0 10px',
      width: '992px',
      height: '469px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1120px',
      height: '530px',
    },
  },
  background: {
    background: `url(${Background}) no-repeat center bottom`,
    backgroundSize: 'cover',
    opacity: '0',
    '&.reveal': {
      opacity: '1',
      transition: 'opacity 3s ease 7s !important',
    },
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
  },
  /*
  icon: {
    width: '50px',
    margin: '20px 0',
    [theme.breakpoints.up('xl')]: {
      width: '70px',
    },
  }*/
}));

export default useHistoryDenyStyles;
