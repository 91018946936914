/* eslint-disable import/prefer-default-export */
export const ThreeColumnsStaticContent = [
  {
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Ut pulvinar accumsan leo ac interdum. Donec at porta ante. Suspendisse imperdiet odio vel tempus bibendum. Proin vel dui et leo aliquam tincidunt eu vel ex. Duis elit felis, posuere in auctor ac, hendrerit at nisi. Sed rutrum nunc nisl, eu condimentum ligula ultrices commodo. Duis euismod in mi ut placerat.",
    link: '/link1'
  },
  {
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Ut pulvinar accumsan leo ac interdum. Donec at porta ante. Suspendisse imperdiet odio vel tempus bibendum. Proin vel dui et leo aliquam tincidunt eu vel ex. Duis elit felis, posuere in auctor ac, hendrerit at nisi. Sed rutrum nunc nisl, eu condimentum ligula ultrices commodo. Duis euismod in mi ut placerat.",
    link: '/link2'
  },
  {
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Ut pulvinar accumsan leo ac interdum. Donec at porta ante. Suspendisse imperdiet odio vel tempus bibendum. Proin vel dui et leo aliquam tincidunt eu vel ex. Duis elit felis, posuere in auctor ac, hendrerit at nisi. Sed rutrum nunc nisl, eu condimentum ligula ultrices commodo. Duis euismod in mi ut placerat.",
    link: '/link2'
  }
];
