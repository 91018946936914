import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1250,
    },
  },
  typography: {
    body1: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Regular, sans-serif',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 400,
      '@media (max-width:768px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    body2: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Light, sans-serif',
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 400,
      '@media (max-width:768px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    h1: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Regular, sans-serif',
      fontSize: '72px',
      lineHeight: '82px',
      fontWeight: 400,
      '@media (max-width:768px)': {
        fontSize: '26px',
        lineHeight: '32px',
      },
      '@media (min-width:1200px)': {
        fontSize: '85px',
        lineHeight: '90px',
      },
    },
    h2: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Light, sans-serif',
      fontSize: '60px',
      lineHeight: '68px',
      fontWeight: 200,
      '@media (max-width:768px)': {
        fontSize: '20px',
        lineHeight: '28px',
      },
      '@media (min-width:1200px)': {
        fontSize: '70px',
        lineHeight: '80px',
      },
    },
    h3: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Regular, sans-serif',
      fontSize: '42px',
      lineHeight: '48px',
      fontWeight: 200,
      '@media (max-width:768px)': {
        fontSize: '22px',
        lineHeight: '28px',
      },
      '@media (min-width:1200px)': {
        fontSize: '54px',
        lineHeight: '64px',
      },
    },
    h4: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Regular, sans-serif',
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 200,
      '@media (max-width:768px)': {
        fontSize: '22px',
        lineHeight: '28px',
      },
      '@media (min-width:1200px)': {
        fontSize: '46px',
        lineHeight: '54px',
      },
    },
    h5: {
      color: '#2f2f31',
      fontFamily: 'Oswald-Regular, sans-serif',
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 200,
      '@media (max-width:768px)': {
        fontSize: '22px',
        lineHeight: '28px',
      },
      '@media (min-width:1200px)': {
        fontSize: '38px',
        lineHeight: '48px',
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
      paper: '#fff7ec',
    },
    primary: { main: '#216645', dark: '#1b573a', light: '#216645' },
    secondary: { main: '#1b573a' },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#D54117',
    },
    divider: '#f0f0f0',
  },
  shape: {
    borderRadius: 10,
  },
});

export default theme;
