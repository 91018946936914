import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import Home from "./pages/Home/index";
import Template from "./pages/template";
import QuiSommesNous from "./pages/QuiSommesNous";
import MentionsLegales from "./pages/MentionsLegales";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
import "./App.css";
import "./style.scss";
import useWindowSize from './utils/useWindowSize'

export const InfoContext = React.createContext({});

const useStyles = makeStyles((t) =>
  createStyles({
    "@global": {
      body: {
        fontFamily: t.typography.body2.fontFamily,
        color: t.typography.body2.color,
        fontSize: t.typography.body2.fontSize,
        lineHeight: t.typography.body2.lineHeight,
        fontWeight: t.typography.body2.fontWeight,
        background: t.palette.background.default,
        margin: 0,
        padding: 0,
      },
    },
  })
);

const App = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const size = useWindowSize();

  useStyles();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      process.env.GOOGLE_ANALYTICS_TOKEN
    ) {
      ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TOKEN);
      ReactGA.pageview(pathname + search);
    }
  }, [pathname, search]);

  return (
    <InfoContext.Provider value={size} >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/qui-sommes-nous" component={QuiSommesNous} />
        <Route exact path="/template" component={Template} />
        <Route exact path="/mentions-legales" component={MentionsLegales} />
        <Route exact path="/politique-confidentialite" component={PolitiqueConfidentialite} />
      </Switch>
    </InfoContext.Provider>
  );
};

export default App;
