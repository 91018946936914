export const quotes = [
    "Les licornes sont incroyables, je suis incroyable, par conséquent je suis une licorne.",
    "On multiplie le bonheur en le partageant.",
    "Nous sommes les liens que nous tissons.",
    "Planter des arbres c’est l’arbre qui cache la forêt.",
    "Fais de ta vie un rêve, et d'un rêve, ta réalité.",
    "Il faut savoir trouver la beauté partout là où elle circule.",
    "Vous êtes l’invisible qui peut faire basculer le monde.",
    "Le vrai bonheur ne dépend que de nous...",
    "Une graine invisible dans le présent c’est un arbre visible dans notre avenir.",
    "Vouloir prouver le réchauffement climatique, c'est vouloir éclairer le jour avec une lampe.",
    "Soyez le changement que vous voulez voir dans ce monde.",
    "La vie n’est pas un combat mais une passion à défendre.",
    "L’Homme n’est pas un loup pour l’Homme.",
    "Un étranger est un ami que je ne connais pas encore.",
    "Tout ce que vous pouvez imaginer est réel.",
    "Rien de grand dans le monde ne s'est accompli sans passion.",
    "Close this world / ʇxǝu ǝɥʇ uǝdO.",
    "Life is not a product.",
    "Le monde n’est pas à vendre, vous non plus.",
    "Ouvrons le monde en oeuvrant pour le nouveau monde.",
    "Sans l’art, la vie serait une erreur.",
    "Aimez les personnes et utilisez les objets – pas l’inverse.",
    "Soyez doux avec la terre.",
    "La nature est belle, regardez là.",
    "Nous sommes tous des frères et des sœurs de la même espèce.",
    "La vie n’est pas un produit.",
    "Vous n’êtes pas à vendre.",
    "Etre pessimiste c’est voir la difficulté dans chaque opportunité.",
    "Etre optimiste c’est voir l’opportunité dans chaque difficulté.",
    "On multiplie la connaissance en le partageant.",
    "Notre solidarité est celle de l'ensemble des habitants de la Terre.",
    "Il n'y a pas d'étrangers ici mais des amis que vous n'avez pas encore rencontrés.",
    "Cela semble toujours impossible jusqu’à ce que ce soit fait.",
    "La folie, est de se comporter de la même manière et de s'attendre à un résultat différent.",
    "On multiplie l’amour en le partageant.",
    "La seule chose que l'on puisse décider est quoi faire du temps qui nous est imparti.",
    "Les peurs tuent l’esprit.",
    "Nous avons le destin que nous méritons à nous de le changer.",
    "C’est quand que tu vas mettre des paillettes dans ton travail ?",
    "Entre bosser et bosser avec Vertuelle la question est vite répondue.",
    "C'est le devoir de chaque homme de rendre au monde au moins autant qu'il en a reçu.",
    "Face à la volonté la peur disparaît.",
    "Nous n'héritons pas de la terre de nos ancêtres, nous l'empruntons à nos enfants.",
    "En imaginant demain, nous donnons de la valeur au présent.",
    "La seule chose que l'on puisse décider est quoi faire du temps qui nous est imparti.",
    "le meilleur déchet est celui qu’on ne produit pas.",
    "La vraie économie circulaire, ce n’est pas celle qui tente d’imiter la nature, c’est celle qui tente d’en faire partie.",
    "Le déchet de demain sera d’origine naturelle et retournera à la terre n’importe où pour l’enrichir.",
    "Derrière le service immatériel se cache une consommation d’une ressource matérielle.",
    "Si c’est gratuit, c’est toi le produit.",
    "Foutu pour foutu, autant que la transition écologique ne soit pas une punition mais une fête.",
    "Redevenons des paysans, ce sera plus dépaysant !"
];
