import React from 'react';
import { Button as CoreButton } from '@material-ui/core';
import clsx from 'clsx';
import useButtonStyle from './Button.style';

const Button = (props) => {
  const classes = useButtonStyle(props);
  const {
    children,
  } = props;

  return (
    <CoreButton
      classes={classes}
      className={clsx(classes.root)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children || null}
    </CoreButton>
  );
};

export default Button;
