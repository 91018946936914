import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useTwoColumnsStyles from './TwoColumnsRightImage.style';

const TwoColumnsRightImage = (props) => {
  const classes = useTwoColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.columns}>

        <div className={classes.content}>
          <Typography
            variant="h5"
            className={clsx(classes.title)}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </Typography>
          <Typography
            variant="body2"
            className={clsx(classes.description)}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor.
        </Typography>
        </div>
        <div className={classes.image} />
      </div>
    </div>
  );
};

export default TwoColumnsRightImage;
