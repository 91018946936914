import React, { useContext } from 'react';
import { Typography, Link } from "@material-ui/core";
import clsx from "clsx";
import useServicesStyles from "./Services.style";
import { Button } from "../../../components/Button";
import arrowLeft from "../../../assets/images/arrow_left.svg";
import arrowRight from "../../../assets/images/arrow_right.svg";
import { Tween, Reveal } from "react-gsap";
import { InfoContext } from '../index'
import BeeAnimation from './Bee.anim'

const Services = (props) => {
  const classes = useServicesStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.scrollWrapper)}>
        <div className={classes.content}>
          <div className={clsx(classes.background, "fp-bg")}></div>
          <Reveal repeat={repeat}>
            <Tween from={{ opacity: 0, ease: "Strong.easeOut" }} duration={2}>
              <Typography variant="body2" className={clsx(classes.title)}>
                Les <span>compétences</span> Que nous cultivons
              </Typography>
            </Tween>
          </Reveal>

          <div className={classes.grid}>
            <div className={classes.wrapper}>
              <Reveal repeat={repeat}>
                <Tween
                  from={{ opacity: 0, ease: "Strong.easeOut" }}
                  duration={2}
                  delay={0.5}
                >
                  <Typography
                    variant="body2"
                    className={clsx(classes.titleCategory)}
                  >
                    Digital
                  </Typography>
                </Tween>
              </Reveal>

              <div className={classes.rowContent}>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={0.65}
                  >
                    <div className={classes.item}>
                      <div className={clsx(classes.iconCategory, classes.pot)}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.titleItem)}
                        >
                          Bannières
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className={clsx(classes.descriptionItem)}
                      >
                        <span>HTML5, VIDÉOS, & STATIQUES</span>
                        <br /> Selon les standards
                        <br /> Sizmek, DCM, ...
                      </Typography>
                    </div>
                  </Tween>
                </Reveal>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={0.75}
                  >
                    <div className={classes.item}>
                      <div className={clsx(classes.iconCategory, classes.pot)}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.titleItem)}
                        >
                          Newsletter
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className={clsx(classes.descriptionItem)}
                      >
                        Design et Intégration <span>Responsives</span>
                      </Typography>
                    </div>
                  </Tween>
                </Reveal>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={0.85}
                  >
                    <div className={classes.item}>
                      <div
                        className={clsx(classes.iconCategory, classes.ruche)}
                      >
                        <Typography
                          variant="body2"
                          className={clsx(classes.titleItem)}
                        >
                          DOOH
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className={clsx(classes.descriptionItem)}
                      >
                        <span>Remontage</span> vidéo
                        <br /> et mise aux specs
                        <br /> <span>sous-titrage</span>
                      </Typography>
                    </div>
                  </Tween>
                </Reveal>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={0.95}
                  >
                    <div className={classes.item}>
                      <div className={clsx(classes.iconCategory, classes.pot)}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.titleItem)}
                        >
                          Sites web
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className={clsx(classes.descriptionItem)}
                      >
                        <span>Landing</span> page
                        <br />
                        Site <span>vitrine</span>
                        <br />
                        Site <span>e-commerce</span>
                        <br />
                        Site <span>institutionnel</span>
                        <br />
                        <span>Jeux concours</span>
                      </Typography>
                    </div>
                  </Tween>
                </Reveal>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={0.95}
                  >
                    <div className={classes.item}>
                      <div
                        className={clsx(classes.iconCategory, classes.ruche)}
                      >
                        <Typography
                          variant="body2"
                          className={clsx(classes.titleItem)}
                        >
                          Acquisition de trafic
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className={clsx(classes.descriptionItem)}
                      >
                        SEO, SEA
                        <br />
                        Content <span>vitrine</span>
                        <br />
                        Netlinking
                        <br />
                        Réseaux sociaux
                        <br />
                      </Typography>
                    </div>
                  </Tween>
                </Reveal>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.col}>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={1.1}
                  >
                    <Typography
                      variant="body2"
                      className={clsx(classes.titleCategory)}
                    >
                      Print
                    </Typography>
                  </Tween>
                </Reveal>

                <div className={classes.rowContent}>
                  <Reveal repeat={repeat}>
                    <Tween
                      from={{ opacity: 0, ease: "Strong.easeOut" }}
                      duration={2}
                      delay={1.2}
                    >
                      <div className={classes.item}>
                        <div
                          className={clsx(classes.iconCategory, classes.pot)}
                        >
                          <Typography
                            variant="body2"
                            className={clsx(classes.titleItem)}
                          >
                            Identité visuelle
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className={clsx(classes.descriptionItem)}
                        >
                          <span>LOGO</span> photo /vidéo
                          <br />
                          <span>CHARTE</span> graphique
                          <br />
                          <span>PRÉSENTATION</span> PPT
                          <br />
                          <span>CARTES</span> de visite
                        </Typography>
                      </div>
                    </Tween>
                  </Reveal>{" "}
                  <Reveal repeat={repeat}>
                    <Tween
                      from={{ opacity: 0, ease: "Strong.easeOut" }}
                      duration={2}
                      delay={1.3}
                    >
                      <div className={classes.item}>
                        <div
                          className={clsx(classes.iconCategory, classes.ruche)}
                        >
                          <Typography
                            variant="body2"
                            className={clsx(classes.titleItem)}
                          >
                            OOH
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className={clsx(classes.descriptionItem)}
                        >
                          <span>Concept, Création</span>
                          <br /> et mise aux specs
                        </Typography>
                      </div>
                    </Tween>
                  </Reveal>
                </div>
              </div>
              <div className={classes.col}>
                <Reveal repeat={repeat}>
                  <Tween
                    from={{ opacity: 0, ease: "Strong.easeOut" }}
                    duration={2}
                    delay={1.3}
                  >
                    <Typography
                      variant="body2"
                      className={clsx(classes.titleCategory)}
                    >
                      Shooting
                    </Typography>
                  </Tween>
                </Reveal>

                <div className={classes.rowContent} id='triggerBeeAnimation'>
                  <Reveal repeat={repeat}>
                    <Tween
                      from={{ opacity: 0, ease: "Strong.easeOut" }}
                      duration={2}
                      delay={1.4}
                    >
                      <div
                        className={classes.item}
                        style={{
                          width: "300px",
                          maxWidth: "300px",
                          flex: "0 0 300px",
                        }}
                      >
                        <div
                          className={clsx(classes.iconCategory, classes.ruche)}
                        >
                          <Typography
                            variant="body2"
                            className={clsx(classes.titleItem)}
                          >
                            Photo - drône
                          </Typography>
                        </div>
                        <Typography
                          variant="body2"
                          className={clsx(classes.descriptionItem)}
                        >
                          <span>REPORTAGE</span> photo /vidéo
                          <br />
                          <span>SHOOTING</span> photo institutionnel
                          <br />
                          <span>e-PACKSHOT</span> photo
                        </Typography>
                      </div>
                    </Tween>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.column} >
          <BeeAnimation />
          <Reveal repeat={repeat}>
            <Tween from={{ opacity: 0, ease: "Strong.easeOut" }} duration={2}>
              <Typography variant="body2" className={clsx(classes.txt)}>
                En choisissant de nous confier vos projets ou de compenser votre
                <br /> production, nous contribuons ensemble à une{" "}
                <span>
                  activité économique
                  <br /> plus solidaire de notre planète.
                </span>
              </Typography>
            </Tween>
          </Reveal>
          <div className={clsx(classes.row, classes.hideOnMobile)}>
            <Reveal repeat={repeat}>
              <Tween
                from={{ opacity: 0, ease: "Strong.easeOut" }}
                duration={2}
                delay={0.55}
              >
                <div className={classes.col}>
                  <img src={arrowLeft} className={clsx(classes.icon)} alt="" />
                </div>
              </Tween>
            </Reveal>
            <Reveal repeat={repeat}>
              <Tween
                from={{ opacity: 0, ease: "Strong.easeOut" }}
                duration={2}
                delay={0.75}
              >
                <div className={classes.col}>
                  <img src={arrowRight} className={clsx(classes.icon)} alt="" />
                </div>
              </Tween>
            </Reveal>
          </div>

          <div className={classes.row}>
            <Reveal repeat={repeat}>
              <Tween
                from={{ opacity: 0, ease: "Strong.easeOut" }}
                duration={2}
                delay={0.85}
              >
                <div className={classes.col}>
                  <Button
                    variant="outlined"
                    href="mailto:bonjour@vertuelle.com"
                    component={Link}
                    className={clsx(classes.button)}
                  >
                    Confiez vos projets
                    <span className="bold">à l’agence Vertuelle</span>
                  </Button>
                </div>
              </Tween>
            </Reveal>
            <Reveal repeat={repeat}>
              <Tween
                from={{ opacity: 0, ease: "Strong.easeOut" }}
                duration={2}
                delay={0.95}
              >
                <div className={classes.col}>
                  <Button
                    variant="outlined"
                    href="mailto:bonjour@association-vertuelle.org"
                    component={Link}
                    className={clsx(classes.button)}
                  >
                    Devenez membre
                    <span className="bold">de l’association vertuelle</span>
                  </Button>
                </div>
              </Tween>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
