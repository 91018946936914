import React, { useContext } from 'react';
import { Typography, Link } from "@material-ui/core";
import clsx from "clsx";
import useReferencesStyles from "./References.style";
import racoonImg from "../../../assets/images/raton.svg";
import arrowRight from "../../../assets/images/arrow_right.svg";
import { Tween, Reveal } from "react-gsap";
import { InfoContext } from '../index'
import FramesAnimation from './Frames.anim'
import { HashLink } from 'react-router-hash-link';

const References = (props) => {
  const classes = useReferencesStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900
  const NavLink = repeat ? Link : HashLink;

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content} id='triggerFramesAnimation'>
        <Reveal repeat={repeat}>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
          >
            <Typography
              variant="h2"
              className={clsx(classes.title)}
            >
              Nos références
            </Typography>
            <div className={classes.wrapper}>
              <div className={classes.column}>
                <FramesAnimation />
              </div>
              <div className={classes.column}>
                <img src={racoonImg} className={clsx(classes.racoon)} alt="" />
                <Typography
                  variant="h3"
                  className={clsx(classes.subtitle)}
                >
                  La confidentialité nous est chère
                </Typography>
                <Typography variant="body2" className={clsx(classes.txt)}>
                  Parce que l’on ne diffuse pas nos photos <br />de famille n’importe où.
                </Typography>
                <Typography variant="body2" className={clsx(classes.txtLink)}>
                  <NavLink
                    key="Contact"
                    href="/#contact"
                    to="/#contact"
                    className={clsx(classes.link)}
                  >
                    <span className={clsx(classes.linkTxt)}>Rencontrons nous pour <br />apprendre à se connaître</span>
                    <img src={arrowRight} className={clsx(classes.icon)} alt="" />
                  </NavLink>
                </Typography>
              </div>
            </div>
          </Tween>
        </Reveal>
      </div>
    </div>
  );
};

export default References;
