import React, { useState, useContext } from 'react';
import _ from 'underscore';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';
import { Typography, ButtonBase, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import SlidingPanel from '../SlidingPanel';
import logoImg from '../../assets/images/logo-Vertuelle-Vector.png';
import bulletImg from '../../assets/images/feuille-bullet.png';
import useHeaderStyles from './Header.style';
import LogoVertuelleAnimation from "./LogoVertuelle.anim";
import { InfoContext } from './../../App'
import IconFacebook from '../../assets/images/jsx/IconFacebook';
import IconVertuelle from '../../assets/images/jsx/IconVertuelle';
import IconInstagram from '../../assets/images/jsx/IconInstagram';

const Header = (props) => {
  const { menu, logo, fixed = true, style } = props;
  const steps = [
    {
      title: 'Notre Histoire',
      path: "/",
      hash: '#history',
      hashlink: false
    }, /* {
      title: 'Qui sommes-nous ?',
      path: "/qui-sommes-nous",
      hash: '',
      hashlink: true
    },*/ {
      title: 'Le nom Vertuelle',
      path: "/",
      hash: '#vertuelle',
      hashlink: false
    }, {
      title: 'Comment planter des arbres',
      path: "/",
      hash: '#how-to-plant',
      hashlink: false
    }, {
      title: 'Nos services',
      path: "/",
      hash: '#services',
      hashlink: false
    }, {
      title: 'Nos références',
      path: "/",
      hash: '#references',
      hashlink: false
    }, {
      title: 'Nous contacter',
      path: "/",
      hash: '#contact',
      hashlink: false
    },
  ];
  const classes = useHeaderStyles(props);
  const [displaySideMenu, setDisplaySideMenu] = useState(false);
  const size = useContext(InfoContext);
  const repeat = size.width > 900

  const NavLink = repeat ? Link : HashLink;

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.wrapperFixed, {
        fixed:fixed
      })} style={style}>
        <div className={classes.wrapper} id='triggerLogoVertuelleAnimation'>
          {menu && (<ButtonBase
            type="button"
            className={classes.burger}
            onClick={() => setDisplaySideMenu(true)}
          >
            <MenuIcon className={classes.iconMenuMobile} />
          </ButtonBase>)}
          {logo && (<Link to="/" className={clsx(classes.logo)}>
            <LogoVertuelleAnimation />
          </Link>)}
        </div>
        <SlidingPanel
          type="left"
          isOpen={!!displaySideMenu}
          size={size.width > 640 ? 30 : 80}
          backdropClicked={() => setDisplaySideMenu(false)}
          panelClassName={classes.slidingPanel}
        >
          <div className={classes.slidingPanel}>
            <ButtonBase
              className={classes.closeButton}
              onClick={() => setDisplaySideMenu(false)}
            >
              <Close style={{ width: '100%', color: 'white' }} />
            </ButtonBase>
            <img src={logoImg} alt="" className={classes.logoImg} />
            <img src={bulletImg} alt="" className={classes.bulletImg} style={{display:'block', margin:'0 auto', maxWidth:'80%'}}/>
            <div className={classes.mobileNavbar}>
              { steps && (
                <>
                  {_.map(steps, (step) => (
                    (step.hashlink ?
                      <HashLink
                        key={step.title}
                        to={step.path}
                        className={classes.link}
                        onClick={() => setDisplaySideMenu(false)}
                      >
                        <Typography variant="body2" component="span">
                          {step.title}
                        </Typography>
                      </HashLink>
                    :
                      <NavLink
                        key={step.title}
                        href={step.path + step.hash}
                        to={step.path + step.hash}
                        className={classes.link}
                        onClick={() => setDisplaySideMenu(false)}
                      >
                        <Typography variant="body2" component="span">
                          {step.title}
                        </Typography>
                      </NavLink>
                    )
                  ))}
                </>
              )}
            </div>
            <img src={bulletImg} alt="" className={classes.bulletImg} style={{ display: 'block', margin: '0 auto', maxWidth: '80%' }} />
            <div className={clsx(classes.social)}>
              <Link href="https://www.facebook.com/vertuelle" target="_blank" className={clsx(classes.footerLink, classes.linkSocial)}>
                <IconFacebook className={clsx(classes.icon)} />
              </Link>
              <Link href="https://www.instagram.com/vertuelle_agency/" target="_blank" className={clsx(classes.footerLink, classes.linkSocial)}>
                <IconInstagram className={clsx(classes.icon)} />
              </Link>
              <Link href="https://www.vertuelle.com" className={clsx(classes.footerLink, classes.linkSocial)}>
                <IconVertuelle className={clsx(classes.icon)} />
              </Link>
            </div>
          </div>
        </SlidingPanel>
      </div>
    </div>
  );
};

export default Header;
