
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_paille.jpg';
import BackgroundTablette from '../../../assets/images/bg_paille_tablette.jpg';
import BackgroundMobile from '../../../assets/images/bg_paille_mobile.jpg';

const useHowToPlantSliderStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        background: `url(${Background}) no-repeat 0 100%`,
        backgroundSize: "cover",
      },
      [theme.breakpoints.down("900")]: {
        background: `url(${BackgroundTablette}) no-repeat center bottom #1b573b`,
        backgroundSize: "100% auto",
      },
      [theme.breakpoints.down("720")]: {
        backgroundImage: `url(${BackgroundMobile})`,
      },
    },
    background: {
      background: `url(${Background}) no-repeat 0 100%`,
      backgroundSize: "cover",
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100vh",
      padding: "4vh",
      flexDirection: "column",
      position: "relative",
      boxSizing: "border-box",
    },
    title: {
      width: "80%",
      color: "white",
      textAlign: "center",
      fontSize: "38px",
      lineHeight: "60px",
      margin: "0 0 10px",
      fontStyle: "italic",
      "& span": {
        fontSize: "48px",
        lineHeight: "60px",
        fontStyle: "normal",
        textTransform: "uppercase",
        [theme.breakpoints.up("xl")]: {
          fontSize: "60px",
          lineHeight: "70px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "28px",
        width: "100%",
        "& span": {
          fontSize: "24px",
          lineHeight: "36px",
        },
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "48px",
        lineHeight: "58px",
      },
    },
    icon: {
      width: "50px",
      margin: "20px 0",
    },
    animation: {
      width: "150vh",
      maxWidth:"1400px",
      position: 'absolute',
      bottom: '0',
      [theme.breakpoints.down('sm')]: {
        display: 'none !important'
      },
    },
    animationMobile: {
      display: 'none !important',
      [theme.breakpoints.down('sm')]: {
        display: 'block !important'
      },
      height: '85vh',
      position: 'absolute',
      bottom: '-5vh',
    }
  })
);

export default useHowToPlantSliderStyles;
