
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_galaxie.jpg';
import BackgroundTablette from '../../../assets/images/bg_galaxie_tablette.jpg';
import BackgroundMobile from '../../../assets/images/bg_galaxie_mobile.jpg';


const useHeroBannerStyles = makeStyles((theme) => createStyles({
  root: {
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.down("md")]: {
      background: `url(${Background}) no-repeat center 100%`,
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("900")]: {
      backgroundImage: `url(${BackgroundTablette})`,
    },
    [theme.breakpoints.down("720")]: {
      backgroundImage: `url(${BackgroundMobile})`,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    position: 'absolute',
    zIndex:'10'
  },
  tween:{
	  width: '100px',
    height: '100px',
    backgroundColor: 'red',
    margin: '0 !important',
    position: 'relative',
    left: 'calc(50% - 50px)'
  },
  background : {
    background: `url(${Background}) no-repeat center 80%`,
    backgroundSize: 'cover',
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
  },
  h1: {
    color: 'white',
    textTransform: 'uppercase',
    zIndex: '2',
  },
  description: {
    color: 'white',
    zIndex: '2'
  },
  logo: {
    width: '50px',
    margin: '20px 0',
    zIndex: '2',
    [theme.breakpoints.up('xl')]: {
      width: '70px',
    },
  }
}));

export default useHeroBannerStyles;
