
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_pattern_blanc.png';

import img1 from '../../../assets/images/macaron_agence.png';
import img2 from '../../../assets/images/macaron_asso.png';
import img3 from '../../../assets/images/macaron_label.png';

import img1Hover from '../../../assets/images/macaron_agence_reveal.png';
import img2Hover from '../../../assets/images/macaron_asso_reveal.png';
import img3Hover from '../../../assets/images/macaron_label_reveal.png';

const useVertuelleFruitsStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '20vh 5vh 5vh 5vh',
    background: `url(${Background}) repeat 0 0`,
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    height:'100vh',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '35vw 20px 20px 20px',
      height:'2000px',
    },
  },
  branch: {
    position: 'absolute',
    top: 0,
    left: '45vw',
    width: '9vw',
    display:'block',
    [theme.breakpoints.down('sm')]: {
      width: '30vw',
      left: '35vw',
    },
  },
  h2: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    fontSize: '42px',
    lineHeight:'52px',
    margin: '0 0 20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  items: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent:'space-between',
    flexDirection: 'row',
    textAlign:'center',
    maxWidth: '1200px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    padding: '2vh',
    flex:'0 0 25%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  title: {
    margin: '0 0 2vh',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 12px',
    },
  },
  pastille: {
    width: '25vh',
    paddingTop:'25vh',
    margin: '0 auto 2vh',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    // transition: 'background-image 300 ease',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto 12px',
      paddingTop: '50%',
    },
    '&.hover1': {
      backgroundImage: `url(${img1})`,
      '&:hover': {
        backgroundImage: `url(${img1Hover})`
      }
    },
    '&.hover2': {
      backgroundImage: `url(${img2})`,
      '&:hover': {
        backgroundImage: `url(${img2Hover})`
      }
    },
    '&.hover3': {
      backgroundImage: `url(${img3})`,
      '&:hover': {
        backgroundImage: `url(${img3Hover})`
      }
    },
  },
  description: {
    margin: '0 0 2vh',
    // fontSize: '3vh',
    // lineHeight:'4vh'
  },
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor:'white',
      color: 'black',
    },
  }
}));

export default useVertuelleFruitsStyles;
