import React, { useEffect } from "react";
import clsx from "clsx";
import historyDenyStyles from "./HistoryDeny.style";

let canvas,
    stage,
    exportRoot,
    fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
    canvas = document.getElementById("DenyAnimation");
    var comp = AdobeAn.getComposition("5722EEAD172040E1BCC7CCC1AB9CB218");
    handleComplete({}, comp);
};

const handleComplete = (evt, comp) => {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib = comp.getLibrary();
    exportRoot = new lib.deni3();
    stage = new lib.Stage(canvas);
    //Registers the "tick" event listener.
    fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", stage);
    };

    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
};

const DenyAnimation = (props) => {
    const classes = historyDenyStyles(props);
    const ScrollMagic = window.ScrollMagic;
    const $ = window.$;

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        // build scene
        new ScrollMagic.Scene({
            triggerElement: "#triggerDenyAnimation",
            duration: "120%",
        })
            .addTo(controller)
            // .addIndicators() // add indicators (requires plugin)
            .on("enter", function (e) {
                init();
                $("#bgDeny").addClass('reveal');
                
                // setTimeout(function () {
                //   $("#DenyAnimation").show();
                // }, 100);
            })
        .on("leave", function (e) {
          $("#bgDeny").removeClass('reveal');
        });
    }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

    return (
        <canvas
            id="DenyAnimation"
            width="1120" height="530"
            className={clsx(classes.denyAnim)}
        ></canvas>
    );
};

export default DenyAnimation;
