import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import _ from 'underscore';
import clsx from 'clsx';
import { Button } from '../Button';
import useThreeColumnsStyles from './ThreeColumns.style';
import { ThreeColumnsStaticContent } from './ThreeColumns.content';

const ThreeColumns = (props) => {
  const classes = useThreeColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.items)}>
        {_.map(ThreeColumnsStaticContent, ({ link, description }, index) => (
          <div className={classes.item} key={index}>
            <Typography
              variant="body2"
              className={clsx(classes.description)}
            >
              {description}
            </Typography>
            <Button
              className={clsx(classes.button)}
              variant="outlined"
              to={link}
            >
              Voir plus
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThreeColumns;
