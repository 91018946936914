import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useHistoryPartOneStyles from "./HistoryPartOne.style";
import { Tween, Reveal } from "react-gsap";
import BigTreeAnimation from "./BigTree.anim";
import CityAnimation from "./City.anim";

const HistoryPartOne = (props) => {
  const classes = useHistoryPartOneStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content}>
        <div className={clsx(classes.background, "fp-bg")}></div>
        <BigTreeAnimation />
        <CityAnimation />
        <Reveal repeat>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
          >
            <Typography
              id="triggerBigTreeAnimation"
              variant="body2"
              className={clsx(classes.title1)}
            >
              Vertuelle,
              <br />
              c’est le fruit d’une profonde remise en question de nos{" "}
              <span>métiers</span>, de nos <span>valeurs</span> et de notre{" "}
              <span>impact</span> environnemental.
            </Typography>
          </Tween>
        </Reveal>
        <Reveal repeat>
          <Tween
            from={{ opacity: 0, y: "-100", ease: "Strong.easeOut" }}
            duration={3}
            delay={1.5}
          >
            <Typography variant="body2" className={clsx(classes.title2)}>
              C’est la volonté d’agir en
              <br />
              <span>
                rendant à la Terre ce
                <br />
                que nous lui prenons.
              </span>
            </Typography>
            <Typography variant="body2" className={clsx(classes.title2)}>
              C’est la volonté d’agir en
              <br />
              <span>
                rendant à la Terre ce
                <br />
                que nous lui prenons.
              </span>
            </Typography>
          </Tween>
        </Reveal>
      </div>
    </div>
  );
};

export default HistoryPartOne;
