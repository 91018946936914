/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useCallback } from "react";
import clsx from "clsx";
import useVertuelleFruitsStyles from "./VertuelleFruits.style"; // import style// import style
import { InfoContext } from '../index' // import context for width dimension

let canvas,
  stage,
  exportRoot,
  fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
  canvas = document.getElementById("SmallBranchAnimation"); // Canvas ID
  var comp = AdobeAn.getComposition("2826BBBFC73FD54984D439D9C6610977"); // unique ID found in index.html exported by Animate ! Be careful with doublons !
  handleComplete({}, comp);
};

const handleComplete = (evt, comp) => {
  var lib = comp.getLibrary();
  exportRoot = new lib.branche_small(); // unique function name found in index.html exported by Animate
  stage = new lib.Stage(canvas);
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener("tick", stage);
  };

  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
};

const ScrollMagic = window.ScrollMagic;
const controller = new ScrollMagic.Controller();
const scene = new ScrollMagic.Scene().addTo(controller)

const SmallBranchAnimation = (props) => {
  const classes = useVertuelleFruitsStyles(props); // use style
  const $ = window.$;
  const size = useContext(InfoContext); // use Context to get width window
  const repeat = size.width > 900 // Dimension Breakpoint
  const [started, setStarted] = useState(false)

  const callback =useCallback(() => {
    init();
    setTimeout(function () {
      $("#SmallBranchAnimation").show(); // Canvas ID
    }, 100);
    setStarted(true)
  },[])
  
  useEffect(() => {
    scene.triggerElement("#triggerSmallBranchAnimation") // Trigger Canvas Animation ID to put on jsx file
    scene.duration("120%")
    scene.triggerHook(0.95);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((!repeat && !started) || repeat) { 
      scene.on("enter", callback)
    }

    if (repeat) {
      scene.on("leave", function (e) {
        $("#SmallBranchAnimation").hide(); // Canvas ID
      });
    }

    if (started && !repeat) {
      scene.off("enter", callback)
    }
  }, [$, ScrollMagic.Controller, ScrollMagic.Scene, callback, repeat, scene, started]);

  return (
    <canvas
      id="SmallBranchAnimation" // Canvas ID
      width="250" // Copy dimensions from index.html exported by Animate
      height="260" // Copy dimensions from index.html exported by Animate
      className={clsx(classes.branch)} // class from style
    ></canvas>
  );
};

export default SmallBranchAnimation;
