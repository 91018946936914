import * as React from "react";

function SvgIconVertuelle(props) {
  return (
    <svg
      data-name="Groupe 20"
      width="1em"
      height="1em"
      viewBox="0 0 43.5 66.25"
      {...props}
    >
      <path
        data-name="logo Vertuelle Vector copie 2"
        d="M13.24 41.98a22.087 22.087 0 006.58 1.94v20.26a2.06 2.06 0 004.12 0V44.05a20.653 20.653 0 0017.82-12.59c4.7-11.01-.44-24.15-11.49-29.35A22.037 22.037 0 0020.92 0 20.707 20.707 0 001.75 12.64c-4.7 11.01.44 24.14 11.49 29.34zm-7.7-27.73a16.585 16.585 0 016.25-7.46 16.789 16.789 0 019.13-2.67 17.746 17.746 0 017.59 1.72c9.05 4.26 13.29 15.03 9.46 24.01a16.585 16.585 0 01-6.25 7.46 16.927 16.927 0 01-7.78 2.62v-3.67l13.12-13.22a2.058 2.058 0 10-2.92-2.9l-10.2 10.27v-6.44l8.63-8.73a2.061 2.061 0 10-2.93-2.9l-5.7 5.76V8.04a2.06 2.06 0 00-4.12 0v10.11l-6.08-6.21a2.061 2.061 0 00-2.95 2.88l9.03 9.22v6.52L8.81 19.71a2.061 2.061 0 10-2.9 2.93l13.91 13.71v3.41a18.431 18.431 0 01-4.82-1.5C5.95 34 1.7 23.23 5.54 14.25z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconVertuelle;