import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useHistoryDenyStyles from "./HistoryDeny.style";
import DenyAnimation from './Deny.anim'
import { Tween, Reveal } from "react-gsap";

const HistoryDeny = (props) => {
  const classes = useHistoryDenyStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content} id='triggerDenyAnimation'>
        <Reveal repeat>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
          >
            <Typography variant="h3" className={clsx(classes.h3)}>
              Business as usual
            </Typography>
            <Typography variant="body2" className={clsx(classes.title)}>
              Pas pour <strong>nous</strong>. Persévérer dans cette voie nous est devenu absurde.
            </Typography>
            <DenyAnimation />
          </Tween>
        </Reveal>
      </div>
      <div className={clsx(classes.background, "fp-bg")} id="bgDeny"></div>
    </div>
  );
};

export default HistoryDeny;
