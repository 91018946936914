
import {
  makeStyles, createStyles,
} from '@material-ui/core';


const useFooterStyle = makeStyles((theme) => createStyles({
  root: {
    padding: '3vh 4vw',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    zIndex: 10,
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  items: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent:'space-between',
    flexDirection: 'row',
    maxWidth: '1200px',
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    flex:'0 1 28%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
  },
  logo: {
    width: '240px',
    margin:'0 0 18px'
  },
  social: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    flexDirection: 'row',
    width: '100%',
    margin:'0 0 20px'
  },
  icon: {
    width: '80px',
    height: '30px',
    fill: '#fff',
    '&:hover': {
      fill:'#000'
    }
  },
  nav: {
    textTransform: 'uppercase',
    '& a': {
      display: 'block',
      margin:'0 0 12px'
    }
  },
  title: {
    fontFamily: 'Oswald-Regular',
    fontSize: '18px',
    lineHeight: '22px',
    color: 'white',
    textTransform:'uppercase'
  },
  subtitle: {
    fontFamily: 'Oswald-Light',
    fontSize: '15px',
    lineHeight: '24px',
    color: 'white',
  },
  mentions: {
    fontSize: '14px',
    color: 'white',
    margin: '25px 0 0'
  },
  creditsAudio: {
    fontSize: '14px',
    color: 'white',
    textTransform: 'none',
  },
  description: {
    fontSize: '14px',
    lineHeight:'22px',
    color: 'white',
    margin: '25px 0 0'
  },
  button: {
    width: '100%',
    borderWidth: '4px',
    fontSize: '20px',
    fontFamily: 'Oswald-Light',
    lineHeight: '26px',
    textAlign:'center',
    '&:hover': {
      backgroundColor:'white',
      color: 'black',
      textDecoration:"none"
    },
    '& > span': {
      display: 'block',
    },
    '& > span > span.bold': {
      fontFamily: 'Oswald-Bold',
      display: 'block',
      fontSize:'22px'
    }
  },
  link: {
    color: 'white',
    cursor: 'pointer',
    padding: '0 12px 0 0',
    border: "none",
    textDecoration: 'none',
    "&:hover": {
      textDecoration:'underline'
    }
  },
  linkSocial: {
    width: '70px',
    height:'70px',
    padding: '0',
    border: '1px solid white',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
    '&:hover > svg': {
      fill: '#000'
    }
  }
}));

export default useFooterStyle;
