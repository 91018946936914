
import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useVertuelleFruitsStyles = makeStyles((theme) => createStyles({
  root: {
    overflow: 'hidden',
  },
  section: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
      '& > div': {
        height: 'auto !important',
      },
      '& > div > div': {
        height: 'auto !important',
      },
      '&#contact > div:nth-child(1)': {
        height: '100vh !important',
      }
      
    },
  }
}));

export default useVertuelleFruitsStyles;
