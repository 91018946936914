
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../assets/images/bg_end.jpg';


const useEmptyBackgroundStyles = makeStyles((theme) => createStyles({
  root: {
    background: `url(${Background}) no-repeat center 100%`,
    backgroundSize:'cover',
    height:'100vh',
  },
}));

export default useEmptyBackgroundStyles;
