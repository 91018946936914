
import {
  makeStyles, createStyles,
} from '@material-ui/core';


const useHistoryPartTwoStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      // padding: "4vh",
      flexDirection: "column",
      position: "relative",
      boxSizing: "border-box",
    },
    title1: {
      position: "absolute",
      top: "8vw",
      color: "white",
      textAlign: "center",
      fontSize: "24px",
      margin: "0 0 10px",
      zIndex: "100",
      [theme.breakpoints.up("1200")]: {
        top: "3vh",
        fontSize: "36px",
        lineHeight: "54px",
      },
      "& span": {
        fontFamily: "Oswald-Bold",
        fontSize: "32px",
        fontStyle: "italic",
        [theme.breakpoints.up("xl")]: {
          fontSize: "36px",
          lineHeight: "54px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        top: "10vh",
        fontSize: "20px",
        "& span": {
          fontSize: "24px",
        },
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "32px",
        lineHeight: "44px",
      },
    },
    title2: {
      fontSize: "62px",
      lineHeight: "72px",
      textAlign: "center",
      color: "white",
      textTransform: "uppercase",
      zIndex: "100",
      "& span": {
        fontFamily: "Oswald-Bold",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "38px",
        "& br": {
          display: "none",
        },
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "72px",
        lineHeight: "80px",
      },
    },
  })
);

export default useHistoryPartTwoStyles;
