
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_pattern_blanc.png';

const useVertuelleFruitsStyles = makeStyles((theme) => createStyles({
  root: {
    background: `url(${Background}) repeat 0 0`,
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      // padding: '20px',
    },
  },
  header: {
    backgroundColor: theme.palette.primary.dark,
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%'
  },
  h2: {
    color: 'white',
    fontStyle: 'italic',
    fontSize: '42px',
    lineHeight: '52px',
    margin: '0 0 20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    flex:'1 1 100%'
  },
  trame: {
    width:'50vw',
    top: '20vh',
    position: 'absolute',
    maxWidth: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  animation: {
    width: '60vw',
    // top: '25vh',
    // position: 'absolute',
    maxWidth: '1200px',
    [theme.breakpoints.down('sm')]: {
      display:'none !important'
    },
  },
  animationMobile: {
    display: 'none !important',
    [theme.breakpoints.down('sm')]: {
      display: 'block !important'
    },
    width: '90vw',
    // position: 'absolute',
    // top: '25vh',
  }
}));

export default useVertuelleFruitsStyles;
