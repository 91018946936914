import React, { useEffect } from "react";
import clsx from "clsx";
import HistoryPartOne from "./HistoryPartOne";
import HistoryPartTwo from "./HistoryPartTwo";
import HistoryDeny from "./HistoryDeny";
import HistoryMushroom from "./HistoryMushroom";
import HistoryPartThree from "./HistoryPartThree";
import VertuelleName from "./VertuelleName";
import VertuelleFruits from "./VertuelleFruits";
import HowToPlant from "./HowToPlant";
import HowToPlantSlider from "./HowToPlantSlider";
import Services from "./Services";
import References from "./References";
import Contact from "./Contact";
import HeroBanner from "./HeroBanner";
import useHomeStyles from "./index.style";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AudioPlayer from "../../components/AudioPlayer";
import useWindowSize from './../../utils/useWindowSize'

export const InfoContext = React.createContext({});

const Home = (props) => {
  const classes = useHomeStyles(props);
  const fullpage = window.fullpage;
  const size = useWindowSize();
  const isFullpage = size.width > 900

  useEffect(() => {
    if (isFullpage) {
      new fullpage("#fullpage", {
        licenseKey: "1C3B621F-CD724E4E-A6979930-D501CB33",
        anchors: ['home', 'history', 'history-2', 'history-deny', 'history-mushroom', 'history-3', 'vertuelle', 'vertuelle-fruits', 'how-to-plant', 'how-to-plant-slider', 'services', 'references','contact'],
        scrollOverflow: true,
        responsiveWidth: 768,
        scrollingSpeed: 1500,
        parallaxKey: "dmVydHVlbGxlLmNvbV96RW5jR0Z5WVd4c1lYZz1wUzU=",
        parallax: true,
        parallaxOptions: {
          type: "reveal",
          percentage: 62,
          property: "translate",
        },
      });
    }

  }, [fullpage, isFullpage, size.width]);

  return (
    <InfoContext.Provider value={size} >
      <div page="home" className={clsx(classes.root)}>
        <Header menu style={{ width: '30%' }} />
        <AudioPlayer />
        <div id="fullpage">
          <div className="section" id={isFullpage ? '' : 'home'}>
            <HeroBanner />
          </div>
          <div className="section" id={isFullpage ? '' : 'history'}>
            <HistoryPartOne />
          </div>
          <div className="section">
            <HistoryPartTwo />
          </div>
          <div className="section">
            <HistoryDeny />
          </div>
          <div className="section">
            <HistoryMushroom />
          </div>
          <div className="section">
            <HistoryPartThree />
          </div>
          <div className="section" id={isFullpage ? '' : 'vertuelle'}>
            <VertuelleName />
          </div>
          <div className={clsx(classes.section, "section")}>
            <VertuelleFruits />
          </div>
          <div className={clsx(classes.section, "section")} id={isFullpage ? '' : 'how-to-plant'}>
            <HowToPlant />
          </div>
          <div className="section">
            <HowToPlantSlider />
          </div>
          <div className={clsx(classes.section, "section")} id={isFullpage ? '' : 'services'}>
            <Services />
          </div>
          <div className={clsx(classes.section, "section")} id={isFullpage ? '' : 'references'}>
            <References />
          </div>
          <div className={clsx(classes.section, "section")} id={isFullpage ? '' : 'contact'}>
            <Contact />
            <Footer />
          </div>
        </div>
      </div>
    </InfoContext.Provider>
  );
};

export default Home;
