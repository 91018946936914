import * as React from "react";

function SvgPlayerOff(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 203.2 167.4"
            {...props}
        >
            <title>Wake Up</title>
            <path d="M161.1,80.8v-1c0-0.2-0.1-0.4-0.2-0.6v-0.6c0-0.2-0.2-0.4-0.5-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.2-0.2-0.4-0.5-0.6l-0.5-0.4
                c-0.2-0.2-0.5-0.2-0.5-0.4l-0.5-0.4c-0.2-0.2-0.5-0.2-0.7-0.4c-0.2,0-0.3-0.1-0.5-0.2c-0.2,0-0.5-0.2-0.7-0.2s-0.5-0.2-0.7-0.2h-2.7
                c-0.2,0-0.5,0-0.7,0.2H152L116,86c-0.2,0-0.5,0.2-0.7,0.2c-0.2,0-0.3,0.1-0.5,0.2h-0.2c-2.4,1-3.9,3.1-3.9,5.5v39.4
                c-6.6,0.6-14,3.6-14,11.3c-0.1,6.3,5.6,11.6,12.7,11.6c7.5,0,11.7-6.1,11.7-15.1V96.3l30.6-9v28.3c-11.6,0-16.5,6.5-16.5,12.4
                c0,6.4,5.8,11.5,13,11.5c7.1,0,12.9-5.2,12.9-11.5v-0.2l0,0v-47H161.1z"/>
            <path d="M76.3,25.8L67,28.3c-3,0.9-5,3.5-4.8,6.3v37.8c-10.3,0-16.7,6.6-16.7,12.5c0,6.4,5.8,11.6,13,11.6c7.2,0,13-6.3,13-12.7
                c0,0,0,0,0,0v-0.2l0,0V39.8l9.6-2.2c3.7-1.2,5.7-4.8,4.4-8.1c0,0,0,0,0,0C84,26.2,80,24.6,76.3,25.8z"/>
            <path d="M171.8,22.1L36.7,157.2c-2.7,2.7-7.2,2.7-9.9,0l0,0c-2.7-2.7-2.7-7.2,0-9.9L161.9,12.2c2.7-2.7,7.2-2.7,9.9,0l0,0
                C174.5,15,174.5,19.4,171.8,22.1z"/>
        </svg>
    );
}

export default SvgPlayerOff;
