import React, { useEffect } from "react";
import clsx from "clsx";
import useHeaderStyles from "./Header.style";

let canvas,
  stage,
  exportRoot,
  fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
  canvas = document.getElementById("LogoVertuelleAnimation");
  var comp = AdobeAn.getComposition("364EE10CFF6BF54F82C53ACC345FCE64");
  var loader = new createjs.LoadQueue(false);
  var lib = comp.getLibrary();
  loader.addEventListener("fileload", function (evt) { handleFileLoad(evt, comp) });
  loader.addEventListener("complete", function (evt) { handleComplete(evt, comp) });
  loader.loadManifest(lib.properties.manifest);
  // handleComplete({}, comp);
};

const handleFileLoad = (evt, comp) => {
  var images = comp.getImages();
  if (evt && (evt.item.type === "image")) { images[evt.item.id] = evt.result; }
}

const handleComplete = (evt, comp) => {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  var ss = comp.getSpriteSheet();
  var queue = evt.target;
  var ssMetadata = lib.ssMetadata;
  for (var i = 0; i < ssMetadata.length; i++) {
    ss[ssMetadata[i].name] = new createjs.SpriteSheet({ "images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames })
  }
  exportRoot = new lib.logo_vertuelle();
  stage = new lib.Stage(canvas);
  if(stage) stage.enableMouseOver();	
  //Registers the "tick" event listener.
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener("tick", stage);
  };

  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
};

const LogoVertuelleAnimation = (props) => {
  const classes = useHeaderStyles(props);
  const ScrollMagic = window.ScrollMagic;
  const $ = window.$;
  const imagesUrl = `${process.env.PUBLIC_URL}/images/`;

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    // build scene
    new ScrollMagic.Scene({
      triggerElement: "#triggerLogoVertuelleAnimation",
      duration: "120%",
    })
      .addTo(controller)
      // .addIndicators() // add indicators (requires plugin)
      .on("enter", function (e) {
        init();

        setTimeout(function () {
          $("#LogoVertuelleAnimation").show();
        }, 100);
      })
      .on("leave", function (e) {
        $("#LogoVertuelleAnimation").hide();
      });
  }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

  return (
    <>
      <div className={clsx(classes.hiddenImages)}>
          <img src={imagesUrl+'_0000_Logo_Blanc_00000png.png'} alt="" />
          <img src={imagesUrl+'_0001_Logo_Blanc_00001png.png'} alt="" />
          <img src={imagesUrl+'_0002_Logo_Blanc_00002png.png'} alt="" />
          <img src={imagesUrl+'_0003_Logo_Blanc_00003png.png'} alt="" />
          <img src={imagesUrl+'_0004_Logo_Blanc_00004png.png'} alt="" />
          <img src={imagesUrl+'_0005_Logo_Blanc_00005png.png'} alt="" />
          <img src={imagesUrl+'_0006_Logo_Blanc_00006png.png'} alt="" />
          <img src={imagesUrl+'_0007_Logo_Blanc_00007png.png'} alt="" />
          <img src={imagesUrl+'_0008_Logo_Blanc_00008png.png'} alt="" />
          <img src={imagesUrl+'_0009_Logo_Blanc_00009png.png'} alt="" />
          <img src={imagesUrl+'_0010_Logo_Blanc_00010png.png'} alt="" />
          <img src={imagesUrl+'_0011_Logo_Blanc_00011png.png'} alt="" />
          <img src={imagesUrl+'_0012_Logo_Blanc_00012png.png'} alt="" />
          <img src={imagesUrl+'_0013_Logo_Blanc_00013png.png'} alt="" />
          <img src={imagesUrl+'_0014_Logo_Blanc_00014png.png'} alt="" />
          <img src={imagesUrl+'_0015_Logo_Blanc_00015png.png'} alt="" />
          <img src={imagesUrl+'_0016_Logo_Blanc_00016png.png'} alt="" />
          <img src={imagesUrl+'_0017_Logo_Blanc_00017png.png'} alt="" />
          <img src={imagesUrl+'_0018_Logo_Blanc_00018png.png'} alt="" />
          <img src={imagesUrl+'_0019_Logo_Blanc_00019png.png'} alt="" />
          <img src={imagesUrl+'_0020_Logo_Blanc_00020png.png'} alt="" />
          <img src={imagesUrl+'_0021_Logo_Blanc_00021png.png'} alt="" />
          <img src={imagesUrl+'_0022_Logo_Blanc_00022png.png'} alt="" />
          <img src={imagesUrl+'_0023_Logo_Blanc_00023png.png'} alt="" />
          <img src={imagesUrl+'_0024_Logo_Blanc_00024png.png'} alt="" />
          <img src={imagesUrl+'_0025_Logo_Blanc_00025png.png'} alt="" />
          <img src={imagesUrl+'_0026_Logo_Blanc_00026png.png'} alt="" />
          <img src={imagesUrl+'_0027_Logo_Blanc_00027png.png'} alt="" />
          <img src={imagesUrl+'_0028_Logo_Blanc_00028png.png'} alt="" />
          <img src={imagesUrl+'_0029_Logo_Blanc_00029png.png'} alt="" />
          <img src={imagesUrl+'_0030_Logo_Blanc_00030png.png'} alt="" />
          <img src={imagesUrl+'_0031_Logo_Blanc_00031png.png'} alt="" />
          <img src={imagesUrl+'_0032_Logo_Blanc_00032png.png'} alt="" />
          <img src={imagesUrl+'_0033_Logo_Blanc_00033png.png'} alt="" />
          <img src={imagesUrl+'_0034_Logo_Blanc_00034png.png'} alt="" />
          <img src={imagesUrl+'_0035_Logo_Blanc_00035png.png'} alt="" />
          <img src={imagesUrl+'_0036_Logo_Blanc_00036png.png'} alt="" />
      </div>
      <canvas
        id="LogoVertuelleAnimation"
        width="300" height="300"
        className={clsx(classes.anim)}
      ></canvas>
    </>
  );
};

export default LogoVertuelleAnimation;
