import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useReferencesStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
      minHeight: '100vh',
      padding: "8vh 0",
      display: 'flex',
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
      position: "relative",
      boxSizing: "border-box",
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "relative",
      boxSizing: "border-box",
      flexDirection: "column",
      [theme.breakpoints.up('lg')]: {
        flexDirection: "row",
      },
    },
    column: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      position:'relative',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '50%',
      },
    },
    title: {
      color: "white",
      textAlign: "center",
      fontSize: "42px",
      lineHeight: "1.4",
      margin: "0",
      fontWeight: 'bold',
      textTransform: "uppercase",
      [theme.breakpoints.up("xl")]: {
        fontSize: "52px",
      },
    },
    subtitle: {
      color: "white",
      textAlign: "center",
      fontSize: "32px",
      lineHeight: "1.4",
      margin: "0 0 30px",
      textTransform: "uppercase",
      [theme.breakpoints.up("xl")]: {
        fontSize: "39px",
      },
    },
    racoon: {
      width: '80px',
      margin: "0 0 20px",
      [theme.breakpoints.up("xl")]: {
        width: "110px",
      },
    },
    txt: {
      color: "white",
      fontSize: "22px",
      lineHeight: "1.4",
      margin: "0",
      textAlign: "center",
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
    txtLink: {
      color: "white",
      fontSize: "22px",
      lineHeight: "1.4",
      margin: "0",
      textAlign: "center",
      position: 'relative',
      [theme.breakpoints.up("xl")]: {
        fontSize: "28px",
      },
    },
    link: {
      color: "white",
      position: 'relative',
      textDecoration: 'none',
      marginTop: '20px',
      display: 'inline-block',
      "& :hover": {
        textDecoration: 'underline',
      }
    },
    linkTxt: {
      display: 'inline-block',
      padding: "0 22px 0 50px"
    },
    icon: {
      width: "22px",
      display: 'inline-block',
      [theme.breakpoints.up("xl")]: {
        width: "28px",
      },
    },
    frames: {
      width: '320px',
      height: '269px',
      marginTop: '-20px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '-30px',
        width: '480px',
        height: '404px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '-40px',
        width: '630px',
        height: '530px',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '-40px',
      },
    }
  })
);

export default useReferencesStyles;
