import React from "react";

import clsx from "clsx";
import useAudioPlayerStyles from "./AudioPlayer.style";

import audioSong from '../../assets/audio/dune_wake_up.mp3';
import PlayerOn from "./assets/PlayerOn";
import PlayerOff from "./assets/PlayerOff";

import useAudioPlayer from './useAudioPlayer';

function AudioPlayer(props) {
    const classes = useAudioPlayerStyles(props);
    const { playing, setPlaying } = useAudioPlayer();
    return (
        <div className={clsx(classes.root)}>
            <audio id="audioSong" src={audioSong} type="audio/mpeg" />
            <div className={clsx(classes.controls)}>
                {playing ?
                    <PlayerOn className={clsx(classes.playerOn)} title="Mute" onClick={() => setPlaying(false)} /> :
                    <PlayerOff className={clsx(classes.playerOff)} title="Wake Up" onClick={() => setPlaying(true)} />
                }
            </div>
        </div>
    );
}

export default AudioPlayer;
