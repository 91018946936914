import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useHistoryPartThreeStyles from "./HistoryPartThree.style";
import HerissonAnimation from './Herisson.anim'
import { Tween, Reveal } from "react-gsap";

const HistoryPartThree = (props) => {
  const classes = useHistoryPartThreeStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content} id='triggerHerissonAnimation'>
        <Reveal repeat>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
          >
            <Typography variant="body2" className={clsx(classes.title)}>
              Et pourtant,
              <br />
              <span>
                Nous croyons qu’un
                <br /> monde vertueux est réel.
              </span>
            </Typography>
            <HerissonAnimation />
          </Tween>
        </Reveal>
      </div>
    </div>
  );
};

export default HistoryPartThree;
