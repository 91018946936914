import React from 'react';
import clsx from 'clsx';
import useTwoColumnsStyles from './OneColumn.style';

const SeeMore = (props) => {
  const classes = useTwoColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <br />
      <h1>Mentions légales</h1>
      <h2>Site VERTUELLE</h2>
      <div className={classes.columns}>
        <p>
          Les Conditions Générales d’Utilisation détaillées ci-dessous ont pour objet de définir les conditions d’utilisation du Site (www.). L’utilisation du Site est ainsi soumise au respect de ces conditions d’utilisation.
        </p>
        <h3>
          Éditeur du site</h3>
        <p>
          VERTUELLE / CBBC<br />
          29 boulevard de la Ferrage<br />
          06400 CANNES<br />
          FRANCE<br />
          SARL au capital de 5 000 Euros<br />
          RCS : 843 171 331<br />
          bonjour@vertuelle.com
        </p>
        <h3>
          Hébergeur du site
        </h3>
        <p>
          OVH<br />
          SAS au capital de 10 069 020 €<br />
          RCS Lille Métropole 424 761 419 00045<br />
          Code APE 2620Z<br />
          N° TVA : FR 22 424 761 419<br />
          Siège social : 2 rue Kellermann - 59100 Roubaix - France
        </p>
        <h3>
              Acceptation préalable des Conditions Générales d’Utilisation
        </h3>
        <p>
          En accédant au Site, l’Utilisateur est réputé avoir pris connaissance des Conditions Générales d’Utilisation et
          s’engage à les respecter à chaque nouvelle utilisation du Site.
        </p>
        <h3>
          Modification des Conditions Générales d’Utilisation et du contenu du Site
</h3>
        <p>
          L’Éditeur du Site se réserve le droit de modifier les Conditions Générales
          d’Utilisation, à tout moment, et ce, sans préavis ni information préalable de
  l’Utilisateur.<br />
  Aucune modification des Conditions Générales d’Utilisation ne pourra entraîner
  une quelconque responsabilité de l’Éditeur. L’Éditeur ne peut garantir à tout
  moment et de manière absolue l’exactitude et l’exhaustivité des informations
  présentes sur le Site. Ainsi, l’Éditeur se réserve donc le droit de corriger
  ou supprimer l’ensemble des éléments composant le Site, sans préavis ni
  information préalable de l’Utilisateur, et ce, sans que sa responsabilité ne
  puisse être engagée de ce fait.<br />
  L’Utilisateur est réputé avoir pris connaissance desdites modifications et
  s’engage à les respecter lors de chaque utilisation du Site.
</p>
        <h3>
          Accès au Site
</h3>
        <p>
          L’Éditeur se réserve la possibilité de retirer ou suspendre l’accès au Site, à
          tout moment, et ce sans préavis ou information préalable de l’Utilisateur.
  <br /><br />
  De ce fait, la responsabilité de l’Éditeur ne saurait être engagée en cas
  d’impossibilité d’accès à tout ou partie du Site.
</p>
        <h3>
          Conditions d’Utilisation du Site
</h3>
        <p>
          L’Utilisateur s’engage à n’utiliser le Site qu’à des fins personnelles et non
          commerciales. Toute utilisation commerciale du Site ou de l’un de ses éléments
          est subordonnée à l’accord préalable de l’Éditeur.
  <br /><br />
  L’Utilisateur s’engage à ne pas utiliser le Site à des fins frauduleuses et à
  n’adopter aucune conduite susceptible de nuire à l’image, aux intérêts ou aux
  droits de l’Éditeur ou de tiers.
        </p>
        
        <h3>
          Propriété Intellectuelle et droit à l’image
</h3>
        <p>
          Le Site ainsi que les éléments le composant (photos, vidéos, éléments
          graphiques, et de manière générale toute information de toute nature) sont la
          propriété exclusive de l’Éditeur et sont protégés au titre du droit d’auteur.
  <br /><br />
  De plus, la dénomination « VERTUELLE» ainsi que les noms, logos et
  dénominations commerciales des sociétés du groupe CBBC (Code Barres Barcode)
  sont des marques déposées et protégées en tant que tel.
  <br /><br />
  De ce fait, toute reproduction, représentation, adaptation, imitation sur
  quelque support que ce soit des éléments susvisés, à des fins publicitaires
  et/ou commerciales est strictement interdite sans autorisation préalable de
  l’Éditeur. Toute exploitation non autorisée engagera la responsabilité de son
  auteur et pourra faire l’objet de poursuites judiciaires au titre du délit de
  contrefaçon, sous réserve des dispositions de l’article L122-5 du Code de la
  propriété intellectuelle.
  <br /><br />
  Par ailleurs, les dénominations, logos, marques, nom de produits ou services
  cités ou reproduits sur le Site ou dans un document publié sur le Site sont la
  propriété exclusive des Annonceurs.
  <br /><br />
  De ce fait, toute reproduction, représentation, adaptation, imitation sur
  quelque support que ce soit de ces éléments sans l’accord préalable de
  l’Annonceur est strictement interdite et pourra faire l’objet de poursuites
  judiciaires au titre du délit de contrefaçon.
  <br /><br />
  Toute personne représentée sur le Site ou dans un document publié sur le Site
  (mannequin, salarié de l’Éditeur, etc.) par le biais de photographies ou de
  films a expressément consenti à l’utilisation de son image dans le cadre du
  Site ou des campagnes produites par les Annonceurs.
  <br /><br />
  Ainsi, toute reproduction, représentation, adaptation, sur quelque support que
  ce soit de ces éléments est strictement interdite sans autorisation préalable
  de l’Éditeur. Toute exploitation non autorisée engagera la responsabilité de
  son auteur et pourra faire l’objet de poursuites judiciaires.
        </p>
        <h3>
          Politique de données personnelles
</h3>
        <p>
          Certaines de vos données personnelles sont recueillies sur notre Site.
          Celles-ci seront traitées conformément à la législation applicable aux données
          personnelles et ce de manière strictement confidentielle.
  <br /><br />
  « Les données à caractère personnel regroupent toute information se rapportant
  à une personne physique identifiée ou identifiable. Une personne physique
  identifiable est une personne physique pouvant être identifiée, directement ou
  indirectement, notamment par référence à un identifiant, tel qu’un nom, un
  numéro d’identification, des données de localisation, un identifiant en ligne,
  ou à un ou plusieurs éléments spécifiques propres à son identité physique,
  physiologique, génétique, psychique, économique, culturelle ou sociale »
  (Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016
  relatif à la protection des personnes physiques à l’égard du traitement des
  données à caractère personnel et à la libre circulation de ces données).
</p>
        <h3>
          Données personnelles concernées
</h3>
        <p>
          Dans le cadre des cookies tiers décrits dans la politique de cookies
          ci-dessous, l’adresse IP de l’utilisateur se connectant au Site et notamment
          sa localisation est collectée. Toutefois, les adresses IP ne font l’objet
          d’aucun stockage et sont par ailleurs traitées en vue d’une anonymisation.
        </p>
        <h3>
          Responsable du traitement des données personnelles
</h3>
        <p>
          L’Éditeur est considéré au regard du Règlement 2016/679 susvisé, comme le
          responsable de traitement des données personnelles collectées sur le Site.
  <br /><br />
  Pour toute question relative au traitement de vos données personnelles, ou
  pour l’exercice de vos droits mentionnés ci-dessous, veuillez contacter notre
  Délégué à la Protection des Données Personnelles à l’adresse email :
  bonjour@vertuelle.com
</p>
        <p>
          Ou à l’adresse suivante :<br />
  VERTUELLE / CBBC<br />
  29 boulevard de la Ferrage<br />
  06400 CANNES<br />
  FRANCE
</p>
        <p>
          Les données personnelles de l’Utilisateur sont susceptibles d’être partagées
          entre l’Éditeur et ses filiales.
  <br /><br />
  Par ailleurs, les données personnelles de l’Utilisateur sont également
  susceptibles d’être partagées avec la société responsable du développement du
  Site, et ce en conformité avec la législation applicable au traitement des
  données personnelles, dans la mesure nécessaire à son activité :<br />
  VERTUELLE / CBBC<br />
  29 boulevard de la Ferrage<br />
  06400 CANNES<br />
  FRANCE
</p>
        <p>
          Les données personnelles de l’Utilisateur ne seront transmises à aucun autre
          tiers, sauf obligation légale.
        </p>
        <h3>
          Finalité du traitement des données personnelles
</h3>
        <p>
          Les données personnelles collectées sur le Site, seront uniquement utilisées à
          des fins d’analyse du Site. Les Données Personnelles de l’Utilisateur ne
          seront en aucun cas utilisées ultérieurement pour d’autres finalités, sous
          réserve du consentement de l’Utilisateur à celles-ci.
</p>
        <p>
          Les données personnelles collectées sur le Site sont utilisées sur la base du
          consentement donné par l’Utilisateur par le biais du formulaire de cookies.
  <br />Ces données ne sont utilisées qu’à des fins d’analyse du Site.
</p>
        <h3>
          Droit des utilisateurs
</h3>
        <p>
          Conformément au Règlement 2016/679 susvisé, l’Utilisateur dispose de
          différents droits sur ses données personnelles, qu’il peut exercer à tout
  moment :<br />
  Droit d’accès aux données personnelles<br />
  Droit de rectification des données personnelles<br />
  Droit à l’effacement des données personnelles<br />
  Droit de limitation du traitement des données personnelles<br />
  Droit à la portabilité des données personnelles<br />
  Droit d’opposition au traitement des données personnelles<br />
  Droit de retrait du consentement au traitement des données personnelles<br />
  Droit de donner des instructions sur le sort des données personnelles après la
  mort de l’Utilisateur
        </p>
        <h3>
          Politique de cookies
</h3>
        <p>
          Certains cookies peuvent être téléchargés par votre navigateur Internet et
          installés sur le matériel que vous utilisez lorsque vous visitez le Site. Ces
          cookies, qui n’identifient pas l’Utilisateur directement, permettent à
          l’Éditeur d’offrir à celui-ci la meilleure expérience possible, en
          sauvegardant ses préférences d’utilisation.
</p>
        <p>
          En cliquant sur le bouton « accepter les cookies » du bandeau d’acceptation
          présent sur la page d’accueil du Site, l’Utilisateur accepte l’utilisation des
          cookies sur son navigateur. L’Utilisateur pourra revenir sur cette décision à
          tout moment en cliquant sur le bouton « paramétrer les cookies » du bandeau
          d’acceptation.
</p>
        <p>
          Deux types de cookies sont présents sur le Site :
  <br /><br />
          <b>Les cookies strictement nécessaires au fonctionnement du Site</b>
          <br /><br />
  Ces cookies sont appelés nécessaires car essentiels à la navigation de
  l’Utilisateur sur le Site et permettent notamment l’utilisation des
  fonctionnalités de base du Site.
</p>
        <p>
          <b>Les cookies tiers</b>
          <br /><br />
  Ces cookies permettent de recueillir des informations au sujet de la
  navigation faite sur le Site à des fins d’analyses statistiques. Ils
  permettent ainsi de mesurer et d’analyser l’utilisation qui est faite du Site
  par les Utilisateurs.
</p>
        <h3>
          Liens hypertextes
</h3>
        <p>
          Le Site est susceptible de contenir des liens vers d’autres sites internet
          privés ou publics appartenant à des tiers.
  <br /><br />
  L’Éditeur ne saurait voir sa responsabilité engagée du fait du contenu de ces
  sites ainsi accessibles, des produits ou services disponibles dessus, des
  conditions d’utilisation, politique d’utilisation des données personnelles ou
  installation de cookies relevant de ces sites.
  <br /><br />
  Par ailleurs, la création de liens hypertextes vers le Site de l’Éditeur est
  soumise à l’accord préalable de l’Éditeur.
        </p>
        <h3>
          Responsabilité
</h3>
        <p>
          La responsabilité de l’Éditeur ne saurait être engagée du fait de dommages
          directs ou indirects survenus à l’occasion de l’utilisation du Site, ou
          résultant des éléments composant le Site.
  <br /><br />
  Par ailleurs, l’Éditeur ne saurait être tenu responsable des dommages
  résultant d’une défaillance d’un outil technique ou technologique permettant
  l’accès ou l’utilisation du Site, tels que les navigateurs web, logiciels,
  ordinateurs, téléphones, sans que cette liste ne soit limitative.
</p>
        <h3>
          Loi applicable et tribunaux compétents
</h3>
        <p>
          Les présentes Conditions Générales d’Utilisation sont intégralement soumises
          au Droit français qui sera seul applicable.
  <br /><br />
  Tout litige relatif à l’interprétation desdites Conditions sera soumis aux
  tribunaux français compétents.
</p>
        <h3>
          Contact
</h3>
        <p>
          Pour toute question relative à un élément composant le Site ou au sujet des
          Conditions Générales d’Utilisation, veuillez nous envoyer un email à :
  <br />bonjour@vertuelle.com <br /><br />
  Ou à l’adresse suivante :<br />
  VERTUELLE / CBBC<br />
  29 boulevard de la Ferrage<br />
  06400 CANNES<br />
  FRANCE
</p>
        <h3>
          Crédits
</h3>
        <p>
          VERTUELLE / CBBC<br />
  29 boulevard de la Ferrage<br />
  06400 CANNES<br />
  FRANCE
</p>

      </div>
    </div>
  );
};

export default SeeMore;
