
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../../assets/images/bg_end.jpg';
import BackgroundTablette from '../../../assets/images/bg_end_tablette.jpg';
import BackgroundMobile from '../../../assets/images/bg_end_mobile.jpg';


const useEmptyBackgroundStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100vh",
      padding: "4vh",
      boxSizing: "border-box",
      position: "relative",
      zIndex: 1,
      [theme.breakpoints.down("901")]: {
        background: `url(${BackgroundTablette}) no-repeat center 100%`,
        backgroundSize: "cover",
      },
      [theme.breakpoints.down("720")]: {
        backgroundImage: `url(${BackgroundMobile})`,
      },
    },
    background: {
      background: `url(${Background}) no-repeat center 100%`,
      backgroundSize: "cover",
      [theme.breakpoints.up("xl")]: {
        background: `url(${Background}) no-repeat center 86%`,
      },
      [theme.breakpoints.down("901")]: {
        display: 'none'
      },
    },
    wrapper: {
      border: `6px solid #76b580`,
      padding: "40px",
      width: "500px",
      margin: "0 auto",
      textAlign: "center",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    title: {
      color: "white",
      fontSize: "50px",
      lineHeight: "60px",
      textAlign: "center",
      margin: "0 0 26px",
      textTransform: "uppercase",
      fontFamily: "Oswald-Bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "40px",
        lineHeight: "50px",
      },
      '@media all and (min-width: 1200px) and (min-height: 700px)': {
        fontSize: "70px",
        lineHeight: "80px",
      },
    },
    description: {
      color: "white",
      fontSize: "32px",
      lineHeight: "42px",
      margin: "0 0 26px",
      textTransform: "uppercase",
      "& span": {
        fontFamily: "Oswald-Bold",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "38px",
      },
      '@media all and (min-width: 1200px) and (min-height: 700px)': {
        fontSize: "54px",
        lineHeight: "68px",
      },
    },
    button: {
      padding: "22px 32px",
      width: "100%",
      borderWidth: "6px",
      fontSize: "36px",
      fontFamily: "Oswald-Light",
      lineHeight: "38px",
      textAlign: 'center',
      "&:hover": {
        backgroundColor: "white",
        color: "black",
        textDecoration: "none"
      },
      "& > span": {
        display: "block",
      },
      "& > span > span.bold": {
        fontFamily: "Oswald-Bold",
        display: "block",
        fontSize: "26px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        fontSize: "18px",
        lineHeight: "28px",
      },
    },
  })
);

export default useEmptyBackgroundStyles;
