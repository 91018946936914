import React from 'react';
import clsx from 'clsx';
import useTwoColumnsStyles from './OneColumn.style';

const SeeMore = (props) => {
  const classes = useTwoColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <br />
      <h1>Politique de protection des données</h1>
      <h2>Site VERTUELLE</h2>
      <div className={classes.columns}>
        <p>Date d’entrée en vigueur : 8 juin 2018<br />
Dernière mise à jour : 8 juin 2020</p>

        <p>
          VERTUELLE / CBBC (ci-après « VERTUELLE » ou « nous ») est conscient que la protection des données à caractère personnel est au cœur de vos préoccupations. Cette politique relative à la protection des données à caractère personnel (ci-après la « Politique de protection des données ») décrit les types de données à caractère personnel des personnes que nous collectons sur le site Internet (www.vertuelle.com) (ci-après le « Site »), la manière dont nous pouvons les utiliser, les personnes ou organismes auxquels nous pouvons les communiquer et les choix disponibles concernant notre utilisation des données. Cette Politique de protection des données décrit également les mesures que nous prenons pour protéger les données à caractère personnel, la durée pendant laquelle nous les conservons et la manière dont les personnes peuvent nous contacter afin de connaitre nos pratiques en matière de protection des données et afin d’exercer leurs droits.
</p>
        <h3>
          Les types de données que nous collectons
</h3>
        <p>
          Dans le cadre de votre utilisation du Site, vous pouvez nous fournir des données à caractère personnel de différentes manières. Les types de données à caractère personnel que nous pouvons recueillir comprennent des informations que vous nous fournissez dans un contenu, comme par exemple lorsque vous nous envoyez un email en cliquant sur les liens de l’adresse de messagerie de VERTUELLE sur le Site. Vous n’avez aucune obligation de nous fournir ces informations mais le fait de ne pas les fournir peut nous empêcher de vous proposer certains services ou certaines fonctionnalités de notre Site.
</p>
        <h3>
          Comment nous utilisons les données que nous collectons
 </h3>
        <p>
          Nous pouvons utiliser les données que nous collectons sur le Site pour les finalités suivantes :<br />
    pour personnaliser votre expérience sur le Site ;<br />
    pour se conformer et faire respecter les dispositions légales applicables, les normes en vigueur dans l’industrie et nos politiques internes.
</p>
        <p>
          Comme exigé par la législation applicable, nous utilisons les données à caractère personnel que nous recueillons pour nous conformer aux exigences légales applicables, remplir nos obligations contractuelles et, comme autorisé par la loi, aux fins des intérêts légitimes que nous poursuivons (à savoir la gestion du Site, la réalisation d’analyses de données et la gestion, l’évaluation et l’amélioration de notre activité). Dans les autres cas, nous recueillons votre consentement pour traiter des données à caractère personnel. Nous pouvons également utiliser les données que nous collectons à d’autres fins pour lesquelles une information spécifique vous est fournie au moment de la collecte.
</p>
        <h3>
          Collecte automatisée de données
</h3>
        <p>
          Lorsque vous naviguez sur le Site, nous pouvons recueillir certaines données par des moyens automatisés, tels que des cookies de navigation. Les données recueillies de cette manière peuvent comprendre l’adresse IP de votre appareil, le nom de domaine, des identifiants associés à vos appareils, le type et les caractéristiques de l’appareil et du système d’exploitation utilisés, les caractéristiques du navigateur Internet, les préférences de langue, les données de flux de clics, vos différentes interactions avec le Site (comme les pages web que vous visitez, les liens sur  lesquels vous cliquez et les fonctions et fonctionnalités que vous utilisez), les pages qui vous ont amené sur le Site ou permis d’accéder au Site, les dates et heures d’accès au Site, ainsi que toutes autres informations concernant votre utilisation du Site.
</p>
        <p>
          Un « cookie » est un fichier texte que les sites Internet envoient à l’ordinateur de l’utilisateur ou à un autre appareil connecté à Internet afin d’identifier de manière unique le navigateur de l’utilisateur ou d’enregistrer des informations ou des paramètres dans le navigateur. Grâce à cette technologie, nous pouvons (1)assurer votre navigation sur le site et rendre les services demandés (2) nous souvenir de vos informations, et ainsi vous éviter de les communiquer de nouveau ; (3) suivre et comprendre la manière dont vous utilisez le Site et interagissez avec celui-ci ; (4) adapter le Site selon vos préférences ; (5) mesurer la facilité d’utilisation et le niveau de convivialité du Site et faire des statistiques pour comprendre votre utilisation du site; (5) mesurer et analyser l’efficacité de nos communications ; et (6) gérer et améliorer de toute autre manière le Site.
</p>
        <p>
          Dès lors que la loi applicable l’exige, nous recueillerons votre consentement avant de déposer des cookies ou autres traceurs sur votre ordinateur. Vous pouvez bloquer le dépôt de certains types de cookies sur votre ordinateur en définissant les paramètres appropriés dans votre navigateur Internet. La plupart des navigateurs Internet vous indiqueront comment refuser de nouveaux cookies de navigation, comment être informé lorsque vous recevez un nouveau cookie de navigation et comment désactiver les cookies existants. Vous pouvez obtenir davantage d’informations sur ces possibilités pour votre propre navigateur en cliquant sur « Aide » dans le menu de votre navigateur ou en vous rendant sur www.allaboutcookies.org. Il est à noter que sans certains cookies ou autres outils automatisés, il est possible que vous ne puissiez pas utiliser l’ensemble des fonctionnalités du Site.
</p>
        <h3>
          Partage des données
</h3>
        <p>
          Nous ne communiquons pas les données à caractère personnel que nous recueillons vous concernant, sauf dans les cas énoncés dans la présente Politique de protection des données. Nous pouvons être amenés à communiquer vos données à caractère personnel à (1) nos filiales et sociétés affiliées et (2) nos fournisseurs et prestataires qui fournissent des prestations en notre nom, comme des services d’analyse de données, pour les finalités décrites dans la présente Politique de protection des données. Nous n’autorisons nos fournisseurs et prestataires à utiliser ou communiquer les données que pour réaliser les prestations en notre nom ou pour se conformer à des obligations légales.
</p>
        <p>
          Nous pouvons également être amenés à communiquer vos données (1) si la loi ou une procédure judiciaire (comme une décision de justice ou une ordonnance de production de pièces) nous y oblige (2) pour répondre  à une demande d’une autorité publique, telle qu’une autorité chargée de veiller au respect de la loi ; (3) pour établir, exercer ou défendre nos droits ; (4) si nous jugeons que cette communication est nécessaire ou utile à la prévention de tout dommage notamment physique ou de toute perte financière ; (5) dans le cadre de toute enquête liée à une activité illégale présumée ou avérée ; (6) en cas de vente ou de transfert de tout ou partie de notre activité ou de nos actifs (y compris en cas de restructuration, dissolution ou liquidation) ; ou (7) avec votre consentement.
</p>
        <h3>
          Vos droits et vos choix
</h3>
        <p>
          Dans toute la mesure permise par la réglementation en vigueur, vous êtes autorisé à (1) demander à accéder aux données à caractère personnel dont nous disposons vous concernant ; (2) demander à ce que nous procédions à la mise jour, la rectification, la modification, l’effacement de ces données ; ou (3) exercer vos droits à la portabilité des données en nous contactant selon les modalités prévues ci-après. Selon l’endroit où vous vous trouvez, vous pouvez avoir le droit d’introduire une réclamation auprès d’une autorité de contrôle s’il s’avère que notre réponse ne vous a pas donné entière satisfaction.  Dans la mesure permise par la loi, vous pouvez, à tout moment, retirer tout consentement à des traitements de données à caractère personnel auxquels vous avez consenti, vous opposer au traitement de vos données à caractère personnel pour des motifs légitimes en lien avec votre situation particulière, ou demander la limitation du traitement de vos données à caractère personnel . Vous pouvez également nous faire part de vos instructions quant à l’utilisation de vos données après votre décès. Afin de protéger votre vie privée et vos données à caractère personnel, et d’assurer votre sécurité, nous pouvons être amenés à prendre des mesures afin de vérifier votre identité avant de vous permettre d’accéder à vos données personnelles. Dans toute la mesure permise par la loi applicable, des frais pourront s’appliquer avant que nous vous fournissions un exemplaire de tout ou partie des données à caractère personnel vous concernant que nous traitons.
</p>
        <h3>
          Autres services en ligne et fonctionnalités proposés par des tiers
</h3>
        <p>
          Le Site peut contenir des liens vers d’autres services en ligne et sites Internet, qui vous sont fournis à titre informatif (par ex., des boutons Facebook, Vimeo, Instagram, LinkedIn et Twitter). Ces services, sites Internet et fonctionnalités tierces peuvent fonctionner indépendamment de nous. Les pratiques de ces tiers en matière de respect de la vie privée et de protection des données à caractère personnel, en ce compris les détails relatifs aux données qu’ils ont pu collecter vous concernant, sont soumis aux politiques de protection des données de ces tiers, que nous vous recommandons vivement de consulter. Dans l’hypothèse où tous services en ligne ou toute fonctionnalité tierce de cet ordre n’appartiendrait pas à VERTUELLE, ou ne serait pas contrôlé par elle, VERTUELLE décline toute responsabilité concernant les pratiques de ces tiers en matière de protection des données à caractère personnel.
</p>
        <h3>
          Conservation des données à caractère personnel
</h3>
        <p>
          Dans toute la mesure permise par la réglementation en vigueur, nous conservons les données à caractère personnel que nous recueillons vous concernant (1) dans la mesure nécessaire pour l’accomplissement des finalités pour lesquelles nous les avons collectées, conformément aux stipulations de la présente Politique de protection des données, (2) et pour la durée de la prescription légale applicable à moins que la loi ne prévoit une autre durée de conservation
</p>
        <h3>
          Données à caractère personnel
</h3>
        <p>
          Le Site vise un grand public et ne s’adresse pas aux enfants âgés de moins de 15 ans. VERTUELLE ne collecte ou ne sollicite sciemment aucune donnée à caractère personnel auprès d’enfants âgés de moins de 15 ans par le biais du Site. S’il est porté à notre connaissance que nous avons collecté des données à caractère personnel auprès d’un enfant âgé de moins de 15 ans, nous supprimerons ces données de nos registres dans les plus brefs délais. Si vous avez des raisons de penser qu’il est possible qu’un enfant âgé de moins de 15 ans nous ait communiqué des données à caractère personnel, veuillez nous contacter en utilisant les coordonnées indiquées dans le paragraphe ci-dessous intitulé « Comment nous contacter ».
 </p>
        <h3>
          Mises à jour de notre Politique de protection des données
</h3>
        <p>
          A moins que la loi ne l’exige autrement, la présente Politique de protection des données peut être mise à jour régulièrement, sans préavis, afin de tenir compte des modifications apportées à nos pratiques en matière de données à caractère personnel. Nous indiquerons en haut de la page la date de la dernière mise à jour.
</p>
        <h3>
          Comment nous contacter
</h3>
        <p>
          Pour mettre à jour vos préférences, mettre à jour ou rectifier vos informations, faire une demande, nous poser des questions sur cette Politique de protection des données, ou encore exercer vos droits relatifs à la protection des données, vous pouvez nous contacter par email à l’adresse bonjour@vertuelle.com
</p>

      </div>
    </div>
  );
};

export default SeeMore;
