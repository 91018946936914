
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../assets/images/img_arbre.jpg';

const useTwoColumnsStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '5vh',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  columns: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent:'space-between',
    flexDirection: 'row',
    maxWidth: '1200px',
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  image: {
    width:'40%',
    background: `url(${Background}) no-repeat center center`,
    backgroundSize: 'cover',
    paddingTop: '38%',
    position: 'relative',
    top: '-10vh',
    [theme.breakpoints.down('sm')]: {
      top: '0px',
      width: '100%',
      paddingTop: '100%',
      marginBottom:'20px'
    },
  },
  content: {
    width: "40%",
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  title: {
    margin:'0 0 20px'
  },
  description: {
    margin: '0 0 10px',
  },
}));

export default useTwoColumnsStyles;
