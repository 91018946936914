/* eslint-disable import/prefer-default-export */


export const VertuelleFruitsStaticContent = [
  {
    title: "La Maison de Communication",
    description: "Agence vertueuse & écoresponsable, par la rétribution solidaire des ressources, pour ancrer le monde de la communication dans la transition écologique."
  },
  {
    title: "L’association Vertuelle",
    description: "Association à but non lucratif d’actions écologiques en France.Pour que notre croyance soit à la hauteur de nos actes et de notre implication."
  },
  {
    title: "Le Label Vertuelle",
    description: "Nous avons l’ambition de créer le Label Vertuelle, mesurant la pollution effective des sites internet, afin d’inciter à rendre le web plus responsable."
  },
];
