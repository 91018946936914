import React, { useContext } from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useHeroBannerStyles from './HeroBanner.style';
import anim_arbre_vector from '../../../assets/images/anim_arbre_vector.svg';
import { Tween } from 'react-gsap';
import Header from "../../../components/Header";
import { InfoContext } from '../index'

const HeroBanner = (props) => {
  const classes = useHeroBannerStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900

  return (
    <div className={clsx(classes.root)}>
      <Header logo fixed={repeat} style={{zIndex:500}} />
      <div className={classes.content}>
        <Tween
          from={{ opacity: 0, y: "200", ease: "Strong.easeOut" }}
          duration={1.5}
        >
          <Typography variant="h1" className={clsx(classes.h1)}>
            Maison de communication
          </Typography>
        </Tween>
        <Tween
          from={{ opacity: 0, y: "200", ease: "Strong.easeOut" }}
          duration={1.5}
          delay={0.5}
        >
          <Typography variant="h2" className={clsx(classes.description)}>
            Écoresponsable, humaniste & vertueuse
          </Typography>
        </Tween>
        <Tween
          from={{
            scaleY: 0,
            scaleX: 0,
            opacity: 0,
            y: "300",
            ease: "Strong.easeOut",
          }}
          duration={1.5}
          delay={1}
        >
          <img src={anim_arbre_vector} className={clsx(classes.logo)} alt="" />
        </Tween>
      </div>
      <div className={clsx(classes.background, "fp-bg")}></div>
    </div>
  );
};

export default HeroBanner;
