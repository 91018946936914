import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    boxShadow: 'none',
    padding: '12px 34px',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '-0.015em',
    fontWeight: 600,
    borderRadius: 0,
    border: '1px solid white',
    color: 'white',
    textTransform:'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
    '&.selected': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  contained: {
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

export default useStyles;
