import React, { useEffect } from "react";
import clsx from "clsx";
import useHistoryPartOneStyles from "./HistoryPartOne.style"; // import style

let canvas,
  stage,
  exportRoot,
  fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
  canvas = document.getElementById("CityAnimation"); // Canvas ID
  var comp = AdobeAn.getComposition("60A782C71C40B347BAF0C5B8694C14B4"); // unique ID found in index.html exported by Animate ! Be careful with doublons !
  handleComplete({}, comp);
};

const handleComplete = (evt, comp) => {
  var lib = comp.getLibrary();
  exportRoot = new lib.ville(); // unique function name found in index.html exported by Animate
  stage = new lib.Stage(canvas);
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener("tick", stage);
  };

  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
};

const CityAnimation = (props) => {
  const classes = useHistoryPartOneStyles(props); // use style
  const ScrollMagic = window.ScrollMagic;
  const $ = window.$;

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: "#triggerBigTreeAnimation", // Trigger Canvas Animation ID to put on jsx file
      duration: "120%",
    })
      .addTo(controller)
      // .addIndicators() // Useful to see triggers
      .on("enter", function (e) {
        init(); 

        setTimeout(function () {
          $("#CityAnimation").show(); // Canvas ID
        }, 100);
      })
      .on("leave", function (e) {
        $("#CityAnimation").hide(); // Canvas ID
      });
  }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

  return (
    <canvas
      id="CityAnimation" // Canvas ID
      width="2000"
      height="1476"
      className={clsx(classes.city)} // class from style
    ></canvas>
  );
};

export default CityAnimation;
