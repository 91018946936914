import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import historyMushroomStyles from "./HistoryMushroom.style";
import { InfoContext } from '../index'

let canvas,
    stage,
    exportRoot,
    fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;
const handleComplete = (evt, comp) => {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib = comp.getLibrary();
    exportRoot = new lib.lavage_vert();

    stage = new lib.Stage(canvas);
    //Registers the "tick" event listener.
    fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", stage);
    };

    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
};

const MushroomAnimation = (props) => {
    const classes = historyMushroomStyles(props);
    const ScrollMagic = window.ScrollMagic;
    const $ = window.$;

    const size = useContext(InfoContext);
    const repeat = size.width > 992
    const animId = repeat ? "5722EEAD172040E1BCC7CCC1AB9CB217" : "5722EEAD172040E1BCC7CCC1AB9CB250";

    const init = () => {
        canvas = document.getElementById("MushroomAnimation");
        var comp = AdobeAn.getComposition(animId);
        handleComplete({}, comp);
    };

    useEffect(() => {
        const controller = new ScrollMagic.Controller();

        // build scene
        new ScrollMagic.Scene({
            triggerElement: "#triggerMushroomAnimation",
            duration: "120%",
        })
        .addTo(controller)
        // .addIndicators() // add indicators (requires plugin)
        .on("enter", function (e) {
            init();
            //$("#bgMushroom").addClass('reveal');
            
            // setTimeout(function () {
            //   $("#MushroomAnimation").show();
            // }, 100);
        })
        /* .on("leave", function (e) {
          $("#bgMushroom").removeClass('reveal');
        }); */
    }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

    return (
        <canvas
            id="MushroomAnimation"
            width="1120" height="530"
            className={clsx(classes.MushroomAnim)}
        ></canvas>
    );
};

export default MushroomAnimation;
