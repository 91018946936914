import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useLayoutStyles = makeStyles((theme) => createStyles({
  root: {
  },
  main: {
  },
}));

export default useLayoutStyles;
