import React, { useEffect } from "react";
import clsx from "clsx";
import useHowToPlantStyles from "../HowToPlant.style";

let canvas,
  stage,
  exportRoot,
  fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
  canvas = document.getElementById("ActionAgroAnim");
  var comp = AdobeAn.getComposition("748D18986432904396B53066753E089F");
  handleComplete({}, comp);
};

const handleComplete = (evt, comp) => {
  //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
  var lib = comp.getLibrary();
  exportRoot = new lib.action_agro_desktop();
  stage = new lib.Stage(canvas);
  //Registers the "tick" event listener.
  fnStartAnimation = function () {
    stage.addChild(exportRoot);
    createjs.Ticker.framerate = lib.properties.fps;
    createjs.Ticker.addEventListener("tick", stage);
  };

  AdobeAn.compositionLoaded(lib.properties.id);
  fnStartAnimation();
};

const ActionAgroAnim = (props) => {
  const classes = useHowToPlantStyles(props);
  const ScrollMagic = window.ScrollMagic;
  const $ = window.$;

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    // build scene
    new ScrollMagic.Scene({
      triggerElement: "#triggerActionAgroAnim",
      duration: "120%",
    })
      .addTo(controller)
      // .addIndicators() // add indicators (requires plugin)
      .on("enter", function (e) {
        init();

        setTimeout(function () {
          $("#ActionAgro").fadeIn();
        }, 100);
      })
      .on("leave", function (e) {
        $("#ActionAgro").fadeOut();
      });
  }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

  return (
    <canvas
      id="ActionAgroAnim"
      width="280" height="280"
      className={clsx(classes.ActionAgroAnim)}
    ></canvas>
  );
};

export default ActionAgroAnim;
