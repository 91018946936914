import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        position: 'relative',
    },
    controls: {
        backgroundColor: 'rgb(27 87 58 / 80%)',
        cursor: 'pointer',
        width: 40,
        height: 40,
        padding: '0 1px',
        position: 'fixed',
        zIndex: 500,
        top: '4vh',
        right: '4vw',
        borderRadius: '50%',
        [theme.breakpoints.down('sm')]: {
            top: '2vh',
            right: '4.6vw',
        },
    },
    playerOn: {
        fill: '#fff',
        position: "relative",
        top: 4,
    },
    playerOff: {
        fill: '#fff',
        position: "relative",
        top: 4,
    },
    nofill: {
        fill: "transparent",
    }
}));

export default useStyles;
