import {
  makeStyles, createStyles,
} from '@material-ui/core';

const useHeaderStyles = makeStyles((theme) => createStyles({
  root: {
    position: 'relative',
  },
  wrapperFixed: {
    display: 'flex',
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    height: 120,
    zIndex: 1000,
    color: 'white',
    transition: 'height 0.3s ease-in',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
      height: 100,
    },
    '&.smaller': {
      [theme.breakpoints.up('sm')]: {
        height: 100,
      },
    },
    '&.fixed': {
      position:'fixed'
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      width: '100%',
    },
  },
  burger: {
    backgroundColor: 'rgb(27 87 58 / 80%)',
    width: 40,
    height: 40,
    position: 'absolute',
    top: '4vh',
    left: '4vw',
    borderRadius: '50%',
    padding: 1,
    [theme.breakpoints.down('sm')]: {
      top: '2vh',
      left: '2vw',
    },
  },
  logo: {
    display: 'block',
    userSelect:'none',
    overflow: 'hidden',
  },
  anim: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-10px',
      width: '200px'
    },

  },
  discoverButton: {
    color: 'white',
    borderColor: 'white',
    borderRadius: 50,
    padding: '8px 28px',
    margin: '0 0 0 20px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  navbar: {
    display: 'flex',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileNavbar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 0 0',
    textAlign: 'center',
    alignItems: 'center',
    '& > a': {
      padding: '6px',
      margin: '6px',
    },
    '& > a > span': {
      color: 'white',
      textTransform:'uppercase',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  iconMenuMobile: {
    width: 38,
    height: 38,
    fill: 'white',
  },
  icon: {
    width: '80px',
    height: '30px',
    fill: '#fff',
    '&:hover': {
      fill: '#000'
    }
  },
  hiddenImages: {
    height: 1,
    width: 1,
    position: 'absolute',
    left: '-1px',
    top: '-1px',
    overflow: 'hidden'
  },
  link: {
    padding: '4px 20px',
    textDecoration: 'none',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    color: 'white',
    border: '3px solid #1d3c2d',
    '&:hover': {
      textDecoration: 'none',
      border:'3px solid #226545'
    },
    '&.active': {
      textDecoration: 'none',
    },
    '&.disabled': {
      pointerEvents: 'none',
      opacity: 0.6,
    },
    '& span': {
      color: 'white',
      fontWeight: 600,
    },
  },
  slidingPanel: {
    backgroundColor: '#1d3c2d',
    position: 'relative',
    // height: '100%',
    textAlign: 'center'
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    backgroundColor: '#1d3c2d',
    width: 25,
    height: 25,
  },
  logoImg: {
    width: "160px",
    margin:'30px 0'
  },
  social: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    flexDirection: 'row',
    width: '90%',
    margin: '20px auto 20px',
  },
  linkSocial: {
    width: '70px',
    height: '70px',
    padding: '0',
    border: '1px solid white',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
    '&:hover > svg': {
      fill: '#000'
    }
  }
}));

export default useHeaderStyles;
