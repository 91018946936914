import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useTwoColumnsStyles from './TwoColumns.style';

const SeeMore = (props) => {
  const classes = useTwoColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <Typography
        variant="h3"
        className={clsx(classes.title)}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, consectetur adipiscing elit.
      </Typography>
      <div className={classes.columns}>
        <Typography
          variant="body2"
          className={clsx(classes.description)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor.
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.description)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor.
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Typography
          variant="h1"
          className={clsx(classes.bottom)}
        >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </Typography>
      </div>
    </div>
  );
};

export default SeeMore;
