
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import Background from '../../assets/images/bg_galaxie.jpg';


const useHeroBannerStyles = makeStyles((theme) => createStyles({
  root: {
    background: `url(${Background}) no-repeat 0 0`,
    backgroundSize:'cover',
    height:'100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection:'column'
  },
  h1: {
    color:'white',
  },
  description: {
    color: 'white',
  },
}));

export default useHeroBannerStyles;
