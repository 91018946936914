/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useHistoryPartTwoStyles from './HistoryPartTwo.style';
import { Reveal } from 'react-gsap';
import logo_header from "../../../assets/images/logo_header.svg";
import Typewriter from "typewriter-effect";

const HistoryPartTwo = (props) => {
  const classes = useHistoryPartTwoStyles(props);
  const $ = window.$;

  useEffect(() => {
    var $trigger = $("#presshold");
    //var $revealer = jQuery("#bgreveal");
    var $wrapper = $("#bg-wrapper");
    //var holdCount = 0;

    var revealSpeed = 300; // in milliseconds.
    var revealSpeedHalf = revealSpeed / 2;
    var revealDelay = 1000; // should match SCSS variable
    var revealSecondDelay = revealDelay + revealSpeedHalf;

    $trigger.on("click", function (e) {
      e.preventDefault();
      $wrapper.addClass("is-activated");

      setTimeout(function () {
        $wrapper.addClass("reveal-fade");
      }, revealDelay);

      setTimeout(function () {
        $wrapper.addClass("transition-complete");
      }, revealSecondDelay);
    });
  }, [$]);

  return (
    <div className={clsx(classes.root)}>
      <svg style={{ display: "none" }}>
        <symbol id="downarrow" viewBox="0 0 10 10">
          <path
            d="M 5 0 L 5 10"
            stroke="white"
            strokeWidth="1"
            strokeLinecap="round"
            className="no-drawsvg"
            fill="none"
          ></path>
          <path
            d="M 0 5 L 5 10 L 10 5"
            stroke="white"
            strokeWidth="1"
            strokeLinecap="round"
            className="no-drawsvg"
            fill="none"
          ></path>
        </symbol>
      </svg>
      <div className={clsx(classes.content, "bg-wrapper")} id="bg-wrapper">
        <Reveal repeat>
          <Typography variant="body2" className={clsx(classes.title1)}>
            Tout commence par la prise de conscience
            <br />
            <span>
              <Typewriter
                options={{
                  strings: ["d’un constat qui nous bouleverse"],
                  autoStart: true,
                  loop: true,
                  delay:50
                }}
              />
              {/* <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("d’un constat qui nous bouleverse")
                    .start();
                }}
              /> */}
            </span>
          </Typography>
        </Reveal>
        <div className="bg-color"></div>
        <div className="bg-blurred"></div>
        <div id="bgreveal" className="bg-reveal">
          <a href="#" id="presshold" className="teaser-arrow teaser-main">
            <span className="teaser-disc"></span>
            <span className="teaser-arrows">
              <img src={logo_header} className={clsx(classes.icon)} alt="" style={{width:'70%',margin:'8px 0'}}/>
              {/* <span class="teaser-arrow-down">
                <svg width="10" height="10">
                  <use xlinkHref="#downarrow"></use>
                </svg>
              </span>
              <span class="teaser-arrow-right">
                <svg width="10" height="10">
                  <use xlinkHref="#downarrow" transform="rotate(-90 5 5)"></use>
                </svg>
              </span>
              <span class="teaser-arrow-up">
                <svg width="10" height="10">
                  <use xlinkHref="#downarrow" transform="rotate(180 5 5)"></use>
                </svg>
              </span>
              <span class="teaser-arrow-left">
                <svg width="10" height="10">
                  <use xlinkHref="#downarrow" transform="rotate(90 5 5)"></use>
                </svg>
              </span> */}
            </span>
            <span className="teaser-label" style={{ color: "white" }}>
              Cliquer ici pour révéler
            </span>
          </a>
        </div>
        {/* <Tween
          from={{ opacity: 0, y: "-20", ease: "Strong.easeOut" }}
          duration={4}
          delay={1}
        >
          <Typography variant="body2" className={clsx(classes.title2)}>
            L’homme fait <span>mal à sa planète</span>
            <br /> et court vers sa chute.
          </Typography>
        </Tween> */}
        {/* <Reveal repeat>
          <Tween
            from={{ opacity: 0, y: "-200", ease: "Strong.easeOut" }}
            duration={4}
            delay={1}
          >
            <Typography variant="body2" className={clsx(classes.title2)}>
              L’homme fait <span>mal à sa planète</span>
              <br /> et court vers sa chute.
            </Typography>
          </Tween>
        </Reveal> */}
      </div>
    </div>
  );
};

export default HistoryPartTwo;
