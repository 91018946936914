import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '../Button';
import useThreeColumnsStyles from './SeeMore.style';

const SeeMore = (props) => {
  const classes = useThreeColumnsStyles(props);

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.items}>
        <Typography
          variant="h5"
          className={clsx(classes.description)}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sapien urna, pretium non sollicitudin a, tincidunt in dolor.
        </Typography>
        <Button
          className={clsx(classes.button)}
          variant="outlined"
          to='/link'
        >
          Voir plus
        </Button>
      </div>
    </div>
  );
};

export default SeeMore;
