import React, { useEffect } from "react";
import clsx from "clsx";
import WiseOwlStyles from "./WiseOwl.style";

let canvas,
    stage,
    exportRoot,
    fnStartAnimation;

const AdobeAn = window.AdobeAn;
const createjs = window.createjs;

const init = () => {
    canvas = document.getElementById("WiseOwlAnimation");
    var comp = AdobeAn.getComposition("36BD38E39A8709449FC933C13EAA604A");
    handleComplete({}, comp);
}

const handleComplete = (evt, comp) => {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib = comp.getLibrary();
    exportRoot = new lib.hibou_sage();
    stage = new lib.Stage(canvas);
    //Registers the "tick" event listener.
    fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", stage);
    }
    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
}

const WiseOwlAnimation = (props) => {
    const classes = WiseOwlStyles(props);
    const ScrollMagic = window.ScrollMagic;
    const $ = window.$;

    useEffect(() => {
        init();
        /*const controller = new ScrollMagic.Controller();

        // build scene
        new ScrollMagic.Scene({
            triggerElement: "#WiseOwlAnimation",
            duration: "120%",
        })
            .addTo(controller)
            // .addIndicators() // add indicators (requires plugin)
            .on("enter", function (e) {
                init();

                // setTimeout(function () {
                //   $("#WiseOwlAnimation").show();
                // }, 100);
            })
        // .on("leave", function (e) {
        //   $("#WiseOwlAnimation").hide();
        // });*/
    }, [$, ScrollMagic.Controller, ScrollMagic.Scene]);

    return (
        <canvas
            id="WiseOwlAnimation"
            width="250" height="250"
            className={clsx(classes.owlAnimation)}
        ></canvas>
    );
};

export default WiseOwlAnimation;
