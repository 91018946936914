
import {
  makeStyles, createStyles,
} from '@material-ui/core';


const useHistoryPartThreeStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.dark,
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '4vh',
    flexDirection: 'column',
    position: 'relative',
    boxSizing: 'border-box'
  },
  herisson: {
    width: '150px',
    margin: '20px 0',
    [theme.breakpoints.down('sm')]: {
      width: '75px',
    },
  },
  title: {
    width:'70%',
    color: 'white',
    textAlign: 'center',
    fontSize: '38px',
    lineHeight:'50px',
    margin: '0 0 10px',
    fontStyle: 'italic',
    [theme.breakpoints.up('xl')]: {
      fontSize: '48px',
      lineHeight: '58px',
    },
    '& span': {
      fontSize: '48px',
      lineHeight: '60px',
      fontStyle: 'normal',
      textTransform: 'uppercase',
      [theme.breakpoints.up('xl')]: {
        fontSize: '56px',
        lineHeight: '64px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '28px',
      width: '100%',
      '& span': {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
  },
  icon: {
    width: '50px',
    margin: '20px 0',
    [theme.breakpoints.up('xl')]: {
      width: '70px',
    },
  }
}));

export default useHistoryPartThreeStyles;
