import React, { useContext } from 'react';
import {
  Typography,
} from '@material-ui/core';
import _ from 'underscore';
import clsx from 'clsx';
import useVertuelleFruitsStyles from './VertuelleFruits.style';
import { VertuelleFruitsStaticContent } from './VertuelleFruits.content';
import { Tween, Reveal } from 'react-gsap';
import SmallBranchAnimation from "./SmallBranch.anim";
import { InfoContext } from '../index'

const VertuelleFruits = (props) => {
  const classes = useVertuelleFruitsStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900

  return (
    <div className={clsx(classes.root)} id="triggerSmallBranchAnimation">
      <SmallBranchAnimation />
      <Reveal repeat={repeat}>
        <Tween
          from={{ opacity: 0, ease: "Strong.easeOut" }}
          duration={1.5}
        >
          <Typography
            variant="h2"
            className={clsx(classes.h2)}
          >
            Les 3 fruits de l’Arbre Vertuelle
          </Typography>
        </Tween>
      </Reveal>
      <Reveal repeat={repeat} threshold={0}>
        <Tween
          from={{ opacity: 0, ease: "Strong.easeOut" }}
          duration={1}
          delay={repeat ? 2 : 0.5}
        >
          <div className={clsx(classes.items)}>
            {_.map(
              VertuelleFruitsStaticContent,
              ({ img, title, description }, index) => (
                <div className={classes.item} key={index}>
                  <div className={clsx(classes.pastille, `hover${index+1}`)} />
                  <Typography variant="body1" className={clsx(classes.title)}>
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={clsx(classes.description)}
                  >
                    {description}
                  </Typography>
                </div>
              )
            )}
          </div>
        </Tween>
      </Reveal>
    </div>
  );
};

export default VertuelleFruits;
