
import {
  makeStyles, createStyles,
} from '@material-ui/core';


const useThreeColumnsStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '5vh',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: '1200px',
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    padding: '2vw',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  description: {
    color: 'white',
    margin: '0 0 20px'
  },
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor:'white',
      color: 'black',
    },
  }
}));

export default useThreeColumnsStyles;
