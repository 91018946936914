import React, { useContext } from 'react';
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useHowToPlantStyles from "./HowToPlant.style";
import img_arbre from "../../../assets/images/img_arbre.jpg";
import { Tween, Reveal } from "react-gsap";
import {
  BranchesAnimation,
  PiouPiouAnimation,
  ActionAgroAnim,
  ActionAgroMobileAnim,
  ActionApiAnim,
  ActionApiMobileAnim,
  ActionSauvageAnim,
  ActionSauvageMobileAnim,
  ActionPlantationAnim,
  LigneFriseSVG
} from './animations'
import { InfoContext } from '../index'

const HowToPlant = (props) => {
  const classes = useHowToPlantStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 768

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.header} id='triggerPiouPiouAnimation'>
        <Reveal repeat={repeat}>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
            delay={1}
          >
            <PiouPiouAnimation />
          </Tween>
        </Reveal>
        <Reveal repeat={repeat}>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={3}
          >
            <Typography variant="body2" className={clsx(classes.title)}>
              Nous voulons agir avec vous à chacune de vos demandes
              <br /> pour <span>réduire votre empreinte écologique.</span>
            </Typography>
            <Typography variant="body2" className={clsx(classes.subtitle)}>
              Répondre aux demandes des entreprises{" "}
              <span>tout en respectant les demandes de la Terre</span> est
              possible !
            </Typography>
          </Tween>
        </Reveal>
      </div>
      <div className={classes.content} id='triggerAllAnimation'>
        <LigneFriseSVG id='triggerSVGAnimation' className={classes.frise} />
        <div className={clsx(classes.row, classes.twoColumns)}>
          <div className={classes.picture}>
            <BranchesAnimation />
            <img src={img_arbre} className={clsx(classes.photo)} alt="" />
          </div>
          <div className={classes.txt}>
            <Reveal rootMargin="300px">
              <Tween
                from={{ opacity: 0, ease: "Strong.easeOut" }}
                duration={2}
                delay={0.5}
              >
                <Typography
                  variant="body1"
                  className={clsx(classes.titleContent)}
                >
                  Nos actions :
                </Typography>
                <Typography
                  variant="body1"
                  className={clsx(classes.subtitleContent)}
                >
                  la plantation d’arbres
                </Typography>
                <ActionPlantationAnim />
                <Typography
                  variant="body1"
                  className={clsx(classes.description)}
                >
                  Pour la séquestration du Carbone
                  <br /> et la création du patrimoine
                  <br /> de demain.
                </Typography>
              </Tween>
            </Reveal>
          </div>
        </div>
        
        <div className={classes.friseMobileContainer}>
          <div className={clsx(classes.row, classes.alignLeft, classes.action1)} id='triggerActionAgroAnim'>
            <div className={clsx(classes.box)} id="ActionAgro">
              {repeat && (<ActionAgroAnim />)}
              {!repeat && (<ActionAgroMobileAnim />)}
                <Typography
                  variant="body1"
                  className={clsx(classes.subtitleContent)}
                >
                  L’Agroforesterie
              </Typography>
                <Typography
                  variant="body1"
                  className={clsx(classes.description)}
                >
                  Pour une agriculture raisonnée,
                <br /> plus de respect de la nature et
                <br /> une meilleure santé.
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.row, classes.alignRight, classes.action2)} id="triggerApiAnimation">
            <div className={clsx(classes.box)} id="ActionApi">
              {repeat && (<ActionApiAnim />)}
              {!repeat && (<ActionApiMobileAnim />)}
              <Typography
                variant="body1"
                className={clsx(classes.subtitleContent)}
              >
                Apiculture
                </Typography>
              <Typography
                variant="body1"
                className={clsx(classes.description)}
              >
                Pour préserver Les insectes
                  <br /> pollinisateurs, la biodiversité
                  <br /> et l’équilibre des écosystèmes.
                </Typography>
            </div>
          </div>
          <div className={clsx(classes.row, classes.alignLeft, classes.action3)} id="triggerSauvageAnimation">
            <div className={clsx(classes.box)} id="ActionSauvage">
              {repeat && (<ActionSauvageAnim />)}
              {!repeat && (<ActionSauvageMobileAnim />)}
              <Typography
                variant="body1"
                className={clsx(classes.subtitleContent)}
              >
                Zones de vies sauvages
              </Typography>
              <Typography
                variant="body1"
                className={clsx(classes.description)}
              >
                Pour redonner des espaces
                <br /> où la nature reprends ses droits.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlant;
