import React, { useContext } from 'react';
import {
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import useHowToPlantSliderStyles from './HowToPlantSlider.style';
import StepperAnimation from './Stepper.anim';
import StepperAnimationMobile from './StepperMobile.anim';
import { Tween, Reveal } from 'react-gsap';
import { InfoContext } from '../index'

const HowToPlantSlider = (props) => {
  const classes = useHowToPlantSliderStyles(props);
  const size = useContext(InfoContext);
  const repeat = size.width > 900

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.content} id='triggerStepperAnimation'>
        <div className={clsx(classes.background, "fp-bg")}></div>
        <Reveal repeat={repeat}>
          <Tween
            from={{ opacity: 0, ease: "Strong.easeOut" }}
            duration={2}
            delay={1}
          >
            <Typography variant="body2" className={clsx(classes.title)}>
              Votre projet va permettre
              <br />
              <span>D’AGIR SUR LA PLANèTE, en 4 étapes.</span>
            </Typography>
          </Tween>
        </Reveal>
        <StepperAnimation />
        <StepperAnimationMobile />
      </div>
    </div>
  );
};

export default HowToPlantSlider;
